import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from '../auth';
import pagesReducer from '../pages';
import dropsReducer from '../drops'

export const store = configureStore({
    reducer: {
        pages: pagesReducer,
        authenticate: authReducer,
        drops: dropsReducer
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
