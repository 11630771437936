import { StandaloneSearchBox } from '@react-google-maps/api';
import React, { createRef, FC, useEffect, useState } from 'react';
import { getDistance } from 'geolib';
import I18n from "../../../../helpers/i18n";
import imageLocation from '../../../../images/location.png'
import arrow from '../../../../images/arrow.png'
import Checkbox from '../../../../components/elements/checkbox/checkbox';
import { interfaceDrop } from '../../../../models/drop';

interface Props {
    onPlacesChange: any,
    onFilterChange: any,
    visibleMarkers: interfaceDrop[],
    onSelectDrop: any
}

const FilterBar: FC<Props> = (props) => {
    const [userLocation, setUserLocation] = useState({lat: 0, lng: 0});
    const [searchBox, setSearchBox] = useState(createRef());

    const [showResults, setShowResults] = useState(false);
    const [showFilterTypes, setShowFilterTypes] = useState(false);

    const [filter, setFilter] = useState({
        onlyWoodchips: true,
        fewLogs: true,
        manyLogs: true,
        onlyLogs: true,
        customSize: true,
        normalSize: true
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setUserLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            }, 
            (err) => { 
                console.log(err)
            });
    }, [])

    const distance = (drop: interfaceDrop) => {
        if (userLocation.lat === 0 && userLocation.lng === 0) return 0
        const distance = getDistance(
            { latitude: drop.latitude, longitude: drop.longitude },
            { latitude: userLocation.lat , longitude: userLocation.lng }
        );

        return distance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    useEffect(() => {
        props.onFilterChange(filter)
    }, [filter])

    const onLoad = (ref: any) => setSearchBox(ref);

    const onPlacesChanged = () => {
        if (searchBox === undefined) return
        // @ts-ignore
        let loc = searchBox.getPlaces()[0].geometry.location;
        let lat = loc.lat();
        let lng = loc.lng();
        props.onPlacesChange({ lat, lng });
    }

    const toggleResults = () => {
        setShowResults(!showResults); 
        setShowFilterTypes(false);
    }

    const toggleTypes = () => {
        setShowFilterTypes(!showFilterTypes);
        setShowResults(false);
    }

    return (
        <div className='filter-bar'>
            <StandaloneSearchBox
                // @ts-ignore
                ref={searchBox.current}
                onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}
            >
                <div className="container-fluid m-0 p-0">
                    <div className="row">
                        <div className="col-12 col-lg-5 container-filter-bar">
                            <div className="input">
                                <img src={imageLocation} alt="Image location" />
                                <input
                                    type="text"
                                    placeholder={I18n.t('LOCATION')}
                                    className="input-location" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 d-flex align-items-center justify-content-between drops">
                            <span className="title">{props.visibleMarkers.length} {I18n.t('RESULTS')}</span>
                            <img src={arrow} alt="Expand" className="arrow" />
                            <div className="close-filters-mobile" onClick={() => toggleResults()}/>
                            <div className={`filter-menu menu-drops ${showResults && "mobile-toggle"}`}>
                                {props.visibleMarkers.length === 0 ? 
                                    <span className="no-drops">
                                        {I18n.t('NO_DROPS_IN_AREA')}
                                    </span>
                                :
                                    props.visibleMarkers.map(drop => {
                                        return (
                                            <div 
                                                className="drop-element"
                                                onClick={() => props.onSelectDrop(drop)} 
                                                key={`filter-drop${drop.id}`}
                                            >
                                                <span className="name">{drop.user_created.first_name} {drop.user_created.last_name}</span>
                                                <span className="distance">{I18n.t('DISTANCE_WITH_DROP', {distance: distance(drop)})}</span>
                                                <span className="highlight">{drop.drop_type.type} | {drop.drop_size.size} </span>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 d-flex align-items-center justify-content-between filter-by">
                            <span className="title">{I18n.t('FILTER_BY')}</span>
                            <img src={arrow} alt="Expand" className="arrow" />
                            <div className="close-filters-mobile" onClick={() => toggleTypes()}/>
                            <div className={`filter-menu ${showFilterTypes && "mobile-toggle"}`}>
                                <div className="filter-form type">
                                    <span className="title-filter">
                                        {I18n.t('FILTER_TYPE')}
                                    </span>
                                    <Checkbox 
                                        onChange={(e: any) => setFilter({
                                            ...filter,
                                            onlyWoodchips: e
                                        })}
                                        checked={filter.onlyWoodchips}
                                        label={I18n.t('FILTER_WOODCHIPS_ONLY')}
                                        name={I18n.t('FILTER_WOODCHIPS_ONLY')} />
                                    <Checkbox 
                                        onChange={(e: any) => setFilter({
                                            ...filter,
                                            fewLogs: e
                                        })}
                                        checked={filter.fewLogs}
                                        label={I18n.t('FILTER_WOODCHIPS_FEW_LOGS')}
                                        name={I18n.t('FILTER_WOODCHIPS_FEW_LOGS')} />
                                    <Checkbox 
                                        onChange={(e: any) => setFilter({
                                            ...filter,
                                            manyLogs: e
                                        })}
                                        checked={filter.manyLogs}
                                        label={I18n.t('FILTER_WOODCHIPS_MANY_LOGS')}
                                        name={I18n.t('FILTER_WOODCHIPS_MANY_LOGS')} />
                                    <Checkbox 
                                        onChange={(e: any) => setFilter({
                                            ...filter,
                                            onlyLogs: e
                                        })}
                                        checked={filter.onlyLogs}
                                        label={I18n.t('FILTER_ONLY_LOGS')}
                                        name={I18n.t('FILTER_ONLY_LOGS')} />
                                </div>
                                <div className="filter-form size">
                                    <span className="title-filter">
                                        {I18n.t('FILTER_SIZE')}
                                    </span>
                                    <Checkbox 
                                        onChange={(e: any) => setFilter({
                                            ...filter,
                                            customSize: e
                                        })}
                                        checked={filter.customSize}
                                        label={I18n.t('FILTER_CUSTOM_REQUEST')}
                                        name={I18n.t('FILTER_CUSTOM_REQUEST')} />
                                    <Checkbox 
                                        onChange={(e: any) => setFilter({
                                            ...filter,
                                            normalSize: e
                                        })}
                                        checked={filter.normalSize}
                                        label={I18n.t('FILTER_NORMAL_LOAD')}
                                        name={I18n.t('FILTER_NORMAL_LOAD')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StandaloneSearchBox>
        </div>
    );
}

export default FilterBar;