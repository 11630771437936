import React, { useState, useEffect } from 'react';

export default function useRecaptcha(key: string) {
    useEffect(() => {
        const loadScriptByURL = (id: string, url: string, callback: any) => {
            const isScriptExist = document.getElementById(id);
        
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                if (callback) callback();
                };
                document.body.appendChild(script);
            }
        
            if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${key}`, function () {
            console.log("Script loaded!");
        });
    }, []);
}
