import React, { useState, FC, useEffect, createRef, useRef, LegacyRef } from 'react';
import I18n from '../../../../helpers/i18n';
import API from '../../../../helpers/api';
import { interfaceDrop, initialDrop } from '../../../../models/drop';
import { GoogleMap, Marker } from '@react-google-maps/api';
import green from '../../../../images/marker-full-free-green.png';
import yellow from '../../../../images/marker-full-free-yellow.png';
import red from '../../../../images/marker-full-free-red.png';
import Legend from '../legend/legend';
import FilterBar from '../filterBar/filterBar';
import InfoWindowComponent from '../InfoWindowComponent/infoWindowComponent';
import { useDispatch, useSelector } from 'react-redux';
import { drops, setDropsRedux } from '../../../../redux/drops';
import { Wrapper } from '@googlemaps/react-wrapper';
import { user } from '../../../../redux/auth';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../components/modal/modal';

const APIManager = API.instance;

interface Props {}

const portland = {
    lat: 45.5428688,
    lng: -122.7944847,
};

const libraries = ['places'];

const MapComponent: FC<Props> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector(user);
    const apiKey = 'AIzaSyAF3aG9i45KWujY6CSwSiY0Sj3Ex-b60Ts';
    const [map, setMap] = useState(createRef<LegacyRef<GoogleMap>>());
    const dropsState = useSelector(drops);
    const [center, setCenter] = useState(portland);
    const [infoWindow, setInfoWindow] = useState<{ open: boolean; drop: interfaceDrop }>({
        open: false,
        drop: initialDrop,
    });
    const [markers, setMarkers] = useState<interfaceDrop[]>([]);
    const [visibleMarkers, setVisibleMarkers] = useState<interfaceDrop[]>([]);
    const [filter, setFilter] = useState({
        onlyWoodchips: true,
        fewLogs: true,
        manyLogs: true,
        onlyLogs: true,
        customSize: true,
        normalSize: true,
    });
    const [isOpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, []);

    useEffect(() => {
        if (dropsState.drops.length === 0) return;
        const newMarkers = dropsState.drops.filter((drop: interfaceDrop) => displayDrop(drop));
        // @ts-ignore
        if (map === undefined || map.getBounds === undefined) return;
        // @ts-ignore
        const markersVisible = newMarkers.filter((drop) =>
            // @ts-ignore
            map.getBounds().contains({ lat: drop.latitude, lng: drop.longitude })
        );
        setMarkers(newMarkers);
        setVisibleMarkers(markersVisible);
    }, [filter, dropsState]);

    const onLoad = (ref: any) => {
        setMap(ref);
        getDrops();
    };

    const onClickMarker = (drop: interfaceDrop) => {
        setInfoWindow({
            open: true,
            drop: drop,
        });
    };

    const onCloseInfoWindow = () => {
        setInfoWindow({
            open: false,
            drop: initialDrop,
        });
    };

    const getDrops = () => {
        APIManager.getDrops()
            .then((res: { data: { data: React.SetStateAction<interfaceDrop[]> } }) => {
                dispatch(setDropsRedux(res.data.data));
                setVisibleMarkers(res.data.data);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const assignDrop = (arboristName: string) => {
        const dropId = infoWindow.drop.id;
        APIManager.assignDrop(dropId, currentUser.id, arboristName)
            .then((res) => {
                history.push('/account/drops-pending');
            })
            .catch((err) => {
                console.log(err);
                onCloseInfoWindow();
                setIsOpenModal(false);
            });
    };

    const iconPriority = (priority: number | null) => {
        if (priority === 3) {
            return red;
        } else if (priority === 2) {
            return yellow;
        } else {
            return green;
        }
    };

    const onChangeMap = () => {
        // @ts-ignore
        if (map === undefined || map.getBounds() === undefined) return;
        // @ts-ignore
        const newMarkers = markers.filter((drop) =>
            // @ts-ignore
            map.getBounds().contains({ lat: drop.latitude, lng: drop.longitude })
        );
        setVisibleMarkers(newMarkers);
    };

    const onPlacesChange = (location: any) => {
        setCenter(location);
    };

    const onFilterChange = (newFilter: any) => {
        setFilter(newFilter);
    };

    const displayDrop = (drop: interfaceDrop) => {
        if (drop.drop_status.id !== 1) return false;
        // if (drop.payment_status.id !== 4) return false;
        if (drop.arborist !== null) return false;

        if (drop.drop_type.id === 1 && filter.onlyWoodchips === false) return false;
        if (drop.drop_type.id === 2 && filter.fewLogs === false) return false;
        if (drop.drop_type.id === 3 && filter.manyLogs === false) return false;
        if (drop.drop_type.id === 4 && filter.onlyLogs === false) return false;

        if (drop.drop_size.id === 1 && filter.customSize === false) return false;
        if (drop.drop_size.id === 2 && filter.normalSize === false) return false;

        return true;
    };

    return (
        <div className="map-wrapper">
            <Modal
                title={I18n.t('MODAL_ASSIGN')}
                body={I18n.t('MODAL_CONFIRMATION_ASSIGN')}
                isOpen={isOpenModal}
                inputBox
                closeModal={() => setIsOpenModal(false)}
                handleConfirm={assignDrop}
            />
            <Wrapper
                apiKey={apiKey}
                // @ts-ignore
                libraries={libraries}
            >
                <FilterBar
                    onPlacesChange={onPlacesChange}
                    onFilterChange={onFilterChange}
                    visibleMarkers={visibleMarkers}
                    onSelectDrop={(drop: interfaceDrop) => onClickMarker(drop)}
                />
                <GoogleMap
                    ref={map.current}
                    onLoad={onLoad}
                    onBoundsChanged={onChangeMap}
                    center={center}
                    mapContainerClassName="map"
                    zoom={11}
                    options={{
                        fullscreenControl: false,
                        streetViewControl: false,
                    }}
                >
                    <Legend />
                    {markers.map((drop) => {
                        return (
                            <Marker
                                key={drop.id}
                                position={{
                                    lat: drop.latitude,
                                    lng: drop.longitude,
                                }}
                                onClick={() => onClickMarker(drop)}
                                icon={iconPriority(drop.priority.id)}
                            />
                        );
                    })}
                    {infoWindow.open && (
                        <InfoWindowComponent
                            drop={infoWindow.drop}
                            onCloseInfoWindow={onCloseInfoWindow}
                            onAssignDrop={() => setIsOpenModal(true)}
                        />
                    )}
                </GoogleMap>
            </Wrapper>
        </div>
    );
};

export default MapComponent;
