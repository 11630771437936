import React, { FC, useEffect, useState } from 'react';
import ModalReact from 'react-modal';
import i18n from '../../helpers/i18n';
import Button from '../elements/button/button';

interface Props {
    title: string;
    body: string;
    isOpen: boolean;
    closeModal: any;
    handleConfirm: (inputText: string) => void;
    inputBox?: boolean;
}

const Modal: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const [inputFieldText, setInputFieldText] = useState('');
    const [canAccept, setCanAccept] = useState(false);

    const handleConfirm = () => {
        setLoader(true);
        props.handleConfirm(inputFieldText);
    };

    useEffect(() => {
        if (inputFieldText.length > 2) {
            setCanAccept(true);
        } else if (props.inputBox == true && inputFieldText.length <= 2) {
            setCanAccept(false);
        }
    }, [inputFieldText]);

    useEffect(() => {
        if (props.inputBox == false || props.inputBox === undefined) {
            setCanAccept(true);
        } else {
            setCanAccept(false);
        }
    }, [props.inputBox]);

    return (
        <ModalReact isOpen={props.isOpen} ariaHideApp={false}>
            <div className="row">
                <div className="col-6">
                    <h3>{props.title}</h3>
                </div>
                <div className="col-6 d-flex flex-row-reverse">
                    <button onClick={props.closeModal}>
                        <div className="close"></div>
                    </button>
                </div>
            </div>
            <div className="content-modal">
                <p>{props.body}</p>

                {props.inputBox && (
                    <div className="d-flex flex-row modal-name">
                        <p>{i18n.t('ARBORIST_NAME')}</p>
                        <div className="modal-input-container">
                            <input
                                type={'text'}
                                value={inputFieldText}
                                className={'input'}
                                onChange={(event: any) => setInputFieldText(event.target.value)}
                                maxLength={400}
                            />
                        </div>
                    </div>
                )}
                <div className="row buttons-modal">
                    <div className="col-12 col-md-6">
                        <Button
                            title={i18n.t('FORM_CANCEL')}
                            fullWidth
                            small
                            clear
                            onClick={() => props.closeModal()}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <Button
                            title={i18n.t('FORM_CONFIRM')}
                            fullWidth
                            loader={loader}
                            small
                            onClick={handleConfirm}
                            disabled={!canAccept}
                        />
                    </div>
                </div>
            </div>
        </ModalReact>
    );
};
export default Modal;
