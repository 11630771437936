import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import { Link } from 'react-router-dom';
import { getSignUpPage, signup } from '../../redux/pages';
import API from '../../helpers/api';
import Type1Image from '../../images/account-type-1.png';
import Type2Image from '../../images/account-type-2.png';
import Button from '../../components/elements/button/button';
import SignUpForm from './signUpForm/signUpForm';
import CompletedSignUp from './completedSignUp/completedSignUp';

const APIManager = API.instance;
interface Props {
}

const SignUpPage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(signup);

    const [type, setType] = useState<any>(null);
    const [selectedAccount, setSelectedAccount] = useState<any>(null);
    const [signUpCompleted, setSignUpCompleted] = useState(false);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getSignUpPage());

        let urlElements = window.location.href.split('/');
        if(urlElements[4] === 'sign-up?type=arborist'){
            setSelectedAccount(2);
            setType(2);
        } else if(urlElements[4] === 'sign-up?type=gardener'){
            setSelectedAccount(1);
            setType(1);
        }
    }, []);

    const changeAccountType = (type: string) => {
        if(type === 'gardener'){
            setSelectedAccount(1);
        } 
        if(type === 'arborist'){
            setSelectedAccount(2);
        }
    }

    const reset = () => {
        // setSelectedAccount(null);
        setType(null);
        window.scrollTo(0, 0)
    }

    const getTitle = () => {
        var title = "";
        var name_type = type == 1 ? I18n.t("GARDENER") : type == 2 ? I18n.t("ARBORIST") : "";
        if(PAGE.page_title !== undefined && PAGE.page_title !== null && type !== null){
            title =  name_type + " " + I18n.t("MENU_SIGNUP").toLowerCase();
        } else if(PAGE.page_title !== undefined && PAGE.page_title !== null){
            title = PAGE.page_title;
        }
        return title;
    }


   
    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
             <section className="sign-up"
                style={{backgroundImage: `url(${APIManager.getImageBaseURL(PAGE.page_background_image)})`}}
            >
                {loader ? <div className="emptystate"/> : <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="content">
                                    <div className="d-flex flex-column align-items-center mb-4">
                                        <h1 className="h2">
                                            {getTitle()}
                                        </h1>
                                        <span className="line -small"/>
                                    </div>
                                    {type === null &&
                                        <div>
                                            <div className="types-wrapper d-flex">
                                                <div className={`select-account-type ${selectedAccount == 1 ? "active" : ""}`} onClick={() => changeAccountType('gardener')}>
                                                    <img src={Type1Image} className="type-img"/>
                                                    <span className="h2 -alt">
                                                        {I18n.t('GARDENER')}
                                                    </span>
                                                    <span className="">
                                                        {I18n.t('SIGNUP_GARDENER_TEXT')}
                                                    </span>
                                                </div>
                                                <div className={`select-account-type ${selectedAccount == 2 ? "active" : ""}`} onClick={() => changeAccountType('arborist')}>
                                                    <img src={Type2Image} className="type-img"/>
                                                    <span className="h2 -alt">
                                                        {I18n.t('ARBORIST')}
                                                    </span>
                                                    <span className="">
                                                        {I18n.t('SIGNUP_ARBORIST_TEXT')}
                                                    </span>
                                                </div>
                                            </div>
                                            <Button 
                                                title={I18n.t('BTN_CONTINUE')}
                                                fullWidth
                                                onClick={() => setType(selectedAccount)}
                                                disabled={selectedAccount == null ? true : false}
                                            />
                                        </div>
                                    }
                                    {
                                        type !== null && !signUpCompleted &&
                                        <div>
                                            <SignUpForm role={type} signUpCompleted={() => setSignUpCompleted(true)}/>
                                            <span className={"back-link"} onClick={() => reset()}>
                                                {I18n.t('LINK_BACK')}
                                            </span>
                                            <span className="divider"/>
                                        </div>
                                    }
                                    {
                                        type !== null && signUpCompleted ?
                                            <div>
                                                <CompletedSignUp />
                                            </div>
                                            :
                                            <div>
                                                <Link
                                                    to={'/login'}
                                                    className="login-link">
                                                        {I18n.t('LOGIN_FROM_SIGNUP')}
                                                </Link>
                                                <Link
                                                    to={'/how-it-works'}
                                                    className="info-link">
                                                        {I18n.t('INFO_FROM_SIGNUP')}
                                                </Link>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
        </Wrapper>
    );
}

export default SignUpPage;