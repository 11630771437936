import React, { useState, FC, useEffect } from 'react';
import I18n from '../../../helpers/i18n';
import { Link } from 'react-router-dom';

interface Props {
    blockImage: string;
    blockTitle: string;
    blockText: string;
    blockUrl: string;
    blockUrlText: string;
    altColorTitle?: boolean;
    altColorLink?: boolean;
}

const ReviewBlock: FC<Props> = (props) => {
    return (
        <div className="row">
            <div className="col-12 col-lg-6 block-image" style={{ backgroundImage: `url(${props.blockImage})` }} />
            <div className="col-12 col-lg-6 p-0">
                <div className="block-text -right bg-green">
                    <div>
                        <h2 className={props.altColorTitle ? '-alt' : ''}>{props.blockTitle}</h2>
                        <span className="page-text">
                            {props.blockText ? props.blockText.split('-').map((str) => <p>{'- ' + str}</p>) : ''}
                        </span>
                        <Link to={props.blockUrl} className={props.altColorLink ? '-alt' : ''}>
                            {props.blockUrlText}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewBlock;
