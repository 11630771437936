import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            const tagManagerArgs = {
                gtmId: 'GTM-PJSX66B',
            };
            TagManager.initialize(tagManagerArgs);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            // ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

export default useGaTracker;
