import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import Placeholder from '../../images/placeholder.jpeg';
import PageBanner from '../../components/blocks/page-banner/page-banner';
import Placeholder_photo1 from '../../images/placeholder_president.jpeg'; 
import Placeholder_photo2 from '../../images/placeholder_cofounder.png'; 
import { Link, useHistory } from 'react-router-dom';
import { getAboutPage, about } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';

const APIManager = API.instance;
interface Props {
}

const AboutPage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(about);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getAboutPage());
    }, []);
    
    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? <div className="emptystate"/> : <div>
                <PageBanner
                    bannerImage={
                        (PAGE.banner_image &&
                        PAGE.banner_image.id !== undefined) && 
                            APIManager.getImageBaseURL(PAGE.banner_image.id)
                    }
                    bannerTitle={PAGE.banner_title}
                />
                <section className="page -about">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-7 d-flex flex-column align-items-center">
                                <h2>
                                    {PAGE.page_title}
                                </h2>
                                <span className="line"/>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mb-5 pb-lg-5">
                            <div className="col-12 col-lg-7 text-align-center">
                                <span className="page-text">
                                    {PAGE.about_text && htmr(PAGE.about_text)} 
                                </span>
                            </div>
                        </div>
                        <div className="row justify-content-around">
                            {(PAGE.team_members !== undefined && PAGE.team_members.length > 0) &&
                                PAGE.team_members.map((item: any) => {
                                    var person = item.Team_members_id;
                                    return (
                                        <div className="col-12 col-lg-5 d-flex jusfify-content-center text-align-center flex-column align-items-center about-block" key={person.id}>
                                            <div className="about-photo" style={{backgroundImage: `url(${(person.photo && person.photo !== undefined) && APIManager.getImageBaseURL(person.photo)})`}}/>
                                            <div className='about-name'>
                                                {person.full_name}
                                            </div>
                                            <div className='about-title'>
                                                {person.role}
                                            </div>
                                            <div className='about-story'>
                                                {person.about}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </div>}
        </Wrapper>
    );
}

export default AboutPage;

