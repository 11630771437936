import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import Cookie from '../../helpers/cookie';
import API from '../../helpers/api';
import I18n from '../../helpers/i18n';
import { initialUser, interfaceUser } from '../../models/user';

const APIManager = API.instance;
const cookie = new Cookie();

interface thisState {
    user: interfaceUser;
    request_status: any;
    login_status: any;
}

const initialState: thisState = {
    user: initialUser,
    request_status: '',
    login_status: '',
};

export const thisSlice = createSlice({
    name: 'authenticate',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setRequestMembershipStatus: (state, action: PayloadAction<any>) => {
            state.request_status = action.payload;
        },
        setLoginStatus: (state, action: PayloadAction<any>) => {
            state.login_status = action.payload;
        },
    },
});
export const { setRequestMembershipStatus, setLoginStatus, setUser } = thisSlice.actions;

export const doLogin =
    (email: string, password: string): AppThunk =>
    (dispatch) => {
        dispatch(setLoginStatus(''));
        APIManager.login(email, password)
            .then((loggedIn) => {
                if (loggedIn) {
                    dispatch(setLoginStatus('success'));
                    APIManager.getProfile()
                        .then((res) => {
                            dispatch(setUser(res.data.data));
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    dispatch(setLoginStatus(I18n.t('LOGIN_ERROR_NOTFOUND')));
                }
            })
            .catch((error) => {
                dispatch(setLoginStatus(I18n.t('LOGIN_ERROR_NOTFOUND')));
            });
    };

// export const setUser = (user: interfaceUser): AppThunk => dispatch => {
//     dispatch(setLoginStatus(''));
//     APIManager.login(email, password).then(loggedIn => {
//         if(loggedIn){
//             dispatch(setLoginStatus('success'));
//             APIManager.getProfile()
//                 .then(res => {
//                     dispatch(setUser(res.data.data))
//                 })
//                 .catch(err => {
//                     console.log(err)
//                 })
//         } else {
//             dispatch(setLoginStatus(I18n.t('LOGIN_ERROR_NOTFOUND')));
//         }
//     }).catch(error => {
//         dispatch(setLoginStatus(I18n.t('LOGIN_ERROR_NOTFOUND')))
//     });
// };

export const clearLoginStatus = (): AppThunk => (dispatch) => {
    dispatch(setLoginStatus(''));
};

export const doLogout = (): AppThunk => (dispatch) => {
    cookie.deleteCookie('token-mulch');
    cookie.deleteCookie('refresh_token');
    cookie.deleteCookie('role');
    dispatch(setLoginStatus(''));
    dispatch(setUser(initialUser));
};

export const request_status = (state: RootState) => state.authenticate.request_status;
export const user = (state: RootState) => state.authenticate.user;
export const login_status = (state: RootState) => state.authenticate.login_status;

export default thisSlice.reducer;
