import { filter } from 'd3';
import { initialUser, interfaceUser } from './user';

export interface interfaceDrop {
    id: number;
    user_created: interfaceUser;
    date_created: string;
    user_updated: interfaceUser;
    date_updated: string;
    description: string;
    image: null | string;
    priority: { id: number; value: string };
    drop_status: { id: number; value: string };
    payment_status: { id: number; value: string };
    address: string;
    zipcode: string;
    city: string;
    state: string;
    latitude: number;
    longitude: number;
    arborist: null | interfaceUser;
    drop_type: { id: number; type: string };
    drop_size: { id: number; size: string };
    cancellations_list: Array<number>;
    lead: null | number;
    photo: any;
}

export const initialDrop: interfaceDrop = {
    id: 0,
    user_created: initialUser,
    date_created: '',
    user_updated: initialUser,
    date_updated: '',
    description: '',
    image: null,
    priority: { id: 0, value: '' },
    drop_status: { id: 0, value: '' },
    payment_status: { id: 0, value: '' },
    address: '',
    zipcode: '',
    city: '',
    state: '',
    latitude: 0,
    longitude: 0,
    arborist: null,
    drop_type: { id: 0, type: '' },
    drop_size: { id: 0, size: '' },
    cancellations_list: [],
    lead: null,
    photo: null,
};
