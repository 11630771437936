import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../../helpers/api';
import I18n from "../../../../helpers/i18n";
import { interfaceDrop } from '../../../../models/drop';
import { user } from '../../../../redux/auth';
import { drops, setDropsRedux } from '../../../../redux/drops';
import DropCardArborist from '../dropCard/dropCardArborist';
import sort_by from '../../../../images/sort_by.svg';

const APIManager = API.instance;
interface Props {
}

const DropsCompleted: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const currentDrops = useSelector(drops);
    const currentUser = useSelector(user);
    const [sort, setSort] = useState(true);

    useEffect(() => {
        APIManager.getDrops()
            .then((res: { data: { data: React.SetStateAction<interfaceDrop[]>; }; }) => {
                dispatch(setDropsRedux(res.data.data))
            })
            .catch((err: any) => {
                console.log(err)
            })
    }, [])

    const conditionCompleted = (drop: interfaceDrop) => {
        return drop.arborist !== null && currentUser.id === drop.arborist.id && drop.drop_status.id === 3
    }

    return (
        <section className='drops'>
            <div className="d-flex justify-content-between">
                <h3>{I18n.t('MENU_DROPS_COMPLETED')}</h3>
                <img src={sort_by} alt="sort drops" onClick={() => setSort(!sort)} className="img-sort"/>
            </div>
            {
                currentDrops.drops.filter((drop: interfaceDrop) => conditionCompleted(drop)).length === 0 &&
                <p>{I18n.t('DROP_ZERO')}</p>
            }
            {
                [...currentDrops.drops]
                    .sort((a, b) => (a.id > b.id) ? (sort ? -1 : 1) : ((b.id > a.id) ? (sort ? 1 : -1) : 0))
                    .map((drop: interfaceDrop) => {
                        if (conditionCompleted(drop)) {
                            return <DropCardArborist drop={drop} key={drop.id} />
                        }
                    })
            }
        </section>
    );
}

export default DropsCompleted;
