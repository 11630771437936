import I18n from './i18n';

export default class Validators { 
    static instance: Validators = new Validators();

    validateString(text: string): [boolean, string] {
        const only_whitespaces = /^\s+$/;
        if (text === '' || only_whitespaces.test(text)) {
            return [true, I18n.t('FORM_ERROR_REQUIRED')]
        }
        return [text === '' ? true : false, I18n.t('FORM_ERROR_REQUIRED')];
    }

    validateRegisterPassword(password: string): [boolean, string]{
        const number_check = /\d/;
        const special_char_check = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if(password.length >= 8 && number_check.test(password) && special_char_check.test(password)){
            return [false, ''];
        } else if(password === ''){
            return [true, I18n.t('FORM_ERROR_REQUIRED')];
        } else if(password.length < 8 || !number_check.test(password) || !special_char_check.test(password)){
            return [true, I18n.t('FORM_PASSWORDS_INVALID')];
        } else {
            return [false, '']
        }
    }

    validateCreateNewPassword(newPassword : string): [boolean, string, boolean]{
        const number_check = /\d/;
        const special_char_check = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (newPassword !== ''){ 
            if(newPassword.length >= 8 && number_check.test(newPassword) && special_char_check.test(newPassword)){
                return [false, '', true];
            } else {
                return [true, I18n.t('FORM_PASSWORDS_INVALID'), false];
            }
        }
        
        return [false, '', false];
    }

    validateConfirmNewPassword(newPassword : string, repeatNewPassword : string): [boolean, string, boolean]{
        if(newPassword === repeatNewPassword){
            return [false, '', repeatNewPassword !== '' ? true : false];
        }
        
        return [true, I18n.t('FORM_PASSWORDS_NOMATCH'), false];
    }

    validateEmail(email: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;

        if (email === '') {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_EMAIL_INVALID');
        } else if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            var emailValid = (reg.test(email) === true);

            errorState = emailValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_EMAIL_INVALID');
        }

        return [errorState, errorMessage];
    }


    validateZipCode(zipCode : string): [boolean, string]{
        const zipRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

        if(zipRegEx.test(zipCode)){
            return [false, ''];
        } else {
            return [true, I18n.t('FORM_ERROR_ZIPCODE')];
        }
    }
}
