import { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from '../../helpers/i18n';
import { useHistory } from 'react-router-dom';
import { getMyAccountPage, my_account } from '../../redux/pages';
import API from '../../helpers/api';
import { doLogout, user, setUser } from '../../redux/auth';
import MenuAccount from './components/menuAccount/menuAccount';
import DropsCompleted from './components/dropsCompleted/dropsCompleted';
import DropsRequest from './components/dropsRequest/dropsRequest';
import DropsReceived from './components/dropsReceived/dropsReceived';
import DropsPending from './components/dropsPending/dropsPending';
import RequestPending from './components/requestsPending/requestsPending';
import AccountDetails from './components/accountDetails/accountDetails';
import { interfaceDrop } from '../../models/drop';
import { setDropsRedux } from '../../redux/drops';
import Modal from '../../components/modal/modal';
import CreateDrop from './components/createDrop/createDrop';
import UpdateDrop from './components/updateDrop/updateDrop';
import RequestService from './components/requestService/requestService';

const APIManager = API.instance;

interface Props {}

const AccountPage: FC<Props> = (props) => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useSelector(user);
    const PAGE = useSelector(my_account);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleLogout = () => {
        dispatch(doLogout());
        history.push('/');
    };

    useEffect(() => {
        if (loader && PAGE !== '') {
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        getDrops();
    }, [history.location.pathname]);

    useEffect(() => {
        setLoader(true);
        dispatch(getMyAccountPage());
    }, []);

    const getDrops = () => {
        APIManager.getProfile()
            .then((res) => {
                dispatch(setUser(res.data.data));

                APIManager.getDrops()
                    .then((res: { data: { data: React.SetStateAction<interfaceDrop[]> } }) => {
                        dispatch(setDropsRedux(res.data.data));
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? (
                <div className="emptystate" />
            ) : (
                <div>
                    <section className="page account-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <div className="column-left">
                                        <h2 className="mb-4">{PAGE.page_title}</h2>
                                        <MenuAccount
                                            role={currentUser.role.name}
                                            doLogout={() => setIsOpenModal(true)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="column-right">
                                        {history.location.pathname === '/account/create' && <CreateDrop />}
                                        {history.location.pathname.includes('/account/request-service') && (
                                            <RequestService />
                                        )}
                                        {history.location.pathname.includes('/account/update') && <UpdateDrop />}
                                        {history.location.pathname === '/account/drops-request' && (
                                            <DropsRequest getDrops={getDrops} />
                                        )}
                                        {history.location.pathname === '/account/drops-received' && <DropsReceived />}
                                        {history.location.pathname === '/account/drops-pending' && (
                                            <DropsPending getDrops={getDrops} />
                                        )}
                                        {history.location.pathname === '/account/requests-pending' && (
                                            <RequestPending />
                                        )}
                                        {history.location.pathname === '/account/drops-completed' && <DropsCompleted />}
                                        {history.location.pathname === '/account/details' && (
                                            <AccountDetails user={currentUser} />
                                        )}
                                    </div>
                                </div>

                                <Modal
                                    title={I18n.t('TEXT_LOGOUT')}
                                    body={I18n.t('')}
                                    isOpen={isOpenModal}
                                    closeModal={() => setIsOpenModal(false)}
                                    handleConfirm={handleLogout}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </Wrapper>
    );
};

export default AccountPage;
