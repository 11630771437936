import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/style.scss';
import DropdownArrow from '../../../images/dropdown-arrow.png';

interface Props {
    onChange?: (event: any) => void,
    onBlur?: (event: any) => void,
    value: string,
    secure?: boolean,
    email?: boolean,
    error?: boolean,
    errorText?: string,
    label: string,
    maxLength?: number,
    textArea?: boolean,
    disabled?: boolean,
    array: any,
}
const Select : FC <Props> = (props) => {  

    useEffect(() => {
    }, []);
    
    return (
        <div>
            <div className={props.error ? "select-wrapper error" : "select-wrapper"}>
                <select 
                    className={props.disabled ? "select disabled" : props.value !== '' ? "select not-empty" : "select"}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    onBlur={props.onBlur}
                    value={props.value}
                >
                    <option value="" disabled></option>
                    {props.array.length > 0 && props.array.map((item: any) => {         
                        return (
                            <option key={item} value={item}>{item}</option>
                        )
                    })}
                </select>
                <label>{props.label}</label>
                <div className="arrow-icon">
                    <img src={DropdownArrow}/>
                </div>
            </div>
            {props.error &&
                <span className="p-small error-text d-block mb-3">{props.errorText}</span>
            }
        </div>
    );
}
export default Select;