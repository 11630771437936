import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from "../../../helpers/i18n";
import { useHistory, useLocation } from "react-router-dom";
import htmr from 'htmr';
interface Props {
    question: string,
    answer: string,
    id?: string
}

const FAQquestion : FC <Props> = (props) => {
    return (
        <div className="row d-flex justify-content-center mt-4" id={props.id}>
            <div className="col-12 col-lg-9">
                <h4 className="mb-2 faq-question">
                    {props.question}
                </h4>
                <span className="faq-answer">
                    {props.answer && htmr(props.answer)}
                </span>
            </div>
        </div>
    );
}

export default FAQquestion;