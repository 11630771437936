import React, { useState, FC, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import MapComponent from './components/mapComponent/mapComponent';
import useGaTracker from '../../navigation/useGaTracker';

interface Props {}

const MapPage: FC<Props> = (props) => {
    useGaTracker();

    return (
        <Wrapper>
            <MapComponent />
        </Wrapper>
    );
};

export default MapPage;
