import React, { FC } from 'react';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import Button from '../../components/elements/button/button';


interface Props {
}

const NoMatchPage : FC <Props> = (props) => {
    return (
        <Wrapper>
            <section className="fourOfour">
                <h4>404</h4>
                <h3>{I18n.t('PAGE_NOT_FOUND')}</h3>
                <div className='container-button'>
                    <Button title={I18n.t('RETURN_HOME')} navTo={'/'} alt small clear/>
                </div>
            </section>
        </Wrapper>
    );
}

export default NoMatchPage;