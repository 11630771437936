import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../helpers/i18n';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../../images/logo-dark.png';
import MenuClosed from '../../images/nav.png';
import MenuOpen from '../../images/close-green.png';
import { doLogout, login_status, user } from '../../redux/auth';
interface Props {}

const Header: FC<Props> = (props) => {
    const loginStatus = useSelector(login_status);
    const currentUser = useSelector(user);
    const [showMenu, setShowMenu] = useState(false);
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        if (loginStatus === 'success' || currentUser.id !== '') {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [loginStatus, currentUser]);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-4">
                            <Link to={{ pathname: '/', state: {} }}>
                                <img src={Logo} alt="Logo" className="logo" />
                            </Link>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                            <div className="d-block d-lg-none menu-icon" onClick={() => toggleMenu()}>
                                <img src={MenuClosed} alt="Menu" />
                            </div>
                            <ul className="menu d-none d-lg-block">
                                {currentUser.role.name === 'Arborist' && (
                                    <li>
                                        <Link to={{ pathname: '/map', state: {} }}>{I18n.t('MENU_MAP')}</Link>
                                    </li>
                                )}

                                {currentUser.role.name === 'Arborist' &&
                                    (currentUser.email === 'alejandro.test.tecdam@gmail.com' ||
                                        currentUser.email === 'rayzak131+arborist@gmail.com') && (
                                        <li>
                                            <Link to={{ pathname: '/admin', state: {} }}>{I18n.t('MENU_ADMIN')}</Link>
                                        </li>
                                    )}
                                <li>
                                    <Link to={{ pathname: '/faq', state: {} }}>{I18n.t('MENU_FAQ')}</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: '/about', state: {} }}>{I18n.t('MENU_ABOUT')}</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: '/donation', state: {} }}>{I18n.t('MENU_DONATION')}</Link>
                                </li>
                                {logged ? (
                                    <li>
                                        <Link
                                            to={{
                                                pathname:
                                                    currentUser.role.name === 'Gardener'
                                                        ? '/account/drops-request'
                                                        : '/account/drops-pending',
                                                state: {},
                                            }}
                                        >
                                            {I18n.t('MENU_ACCOUNT')}
                                        </Link>
                                    </li>
                                ) : (
                                    <>
                                        <li>
                                            <Link to={{ pathname: '/login', state: {} }}>{I18n.t('MENU_LOGIN')}</Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: '/sign-up', state: {} }}>
                                                {I18n.t('MENU_SIGNUP')}
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <div className={showMenu ? 'bg-menu show' : 'bg-menu'} onClick={() => toggleMenu()}>
                <div className={showMenu ? 'mobile-menu show' : 'mobile-menu'}>
                    <div className="close-icon" onClick={() => toggleMenu()}>
                        <img src={MenuOpen} alt="Menu" />
                    </div>
                    <ul className="menu">
                        {currentUser.role.name === 'Arborist' && (
                            <li>
                                <Link to={{ pathname: '/map', state: {} }}>{I18n.t('MENU_MAP')}</Link>
                            </li>
                        )}
                        <li>
                            <Link to={{ pathname: '/', state: {} }}>{I18n.t('MENU_HOME')}</Link>
                        </li>
                        <li>
                            <Link to={{ pathname: '/faq', state: {} }}>{I18n.t('MENU_FAQ')}</Link>
                        </li>
                        <li>
                            <Link to={{ pathname: '/about', state: {} }}>{I18n.t('MENU_ABOUT')}</Link>
                        </li>
                        <li>
                            <Link to={{ pathname: '/donation', state: {} }}>{I18n.t('MENU_DONATION')}</Link>
                        </li>
                        {logged ? (
                            <li>
                                <Link
                                    to={{
                                        pathname:
                                            currentUser.role.name === 'Gardener'
                                                ? '/account/drops-request'
                                                : '/account/drops-pending',
                                        state: {},
                                    }}
                                >
                                    {I18n.t('MENU_ACCOUNT')}
                                </Link>
                            </li>
                        ) : (
                            <>
                                <li>
                                    <Link to={{ pathname: '/login', state: {} }}>{I18n.t('MENU_LOGIN')}</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: '/sign-up', state: {} }}>{I18n.t('MENU_SIGNUP')}</Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
