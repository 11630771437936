import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import VideoContainer from '../../components/blocks/video-container/video-container';
import { Link } from 'react-router-dom';
import { getAboutGardenerPage, about_gardener } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
const APIManager = API.instance;
interface Props {
}

const AboutGardenerPage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(about_gardener);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getAboutGardenerPage());
    }, []);
   
    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? <div className="emptystate"/> : <div>
                <section className="page">
                    <div className="container mb-5">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-7 text-align-center">
                                <h2 className="mb-4">
                                    {PAGE.page_title}
                                </h2>
                                <span className="page-text">
                                    {PAGE.page_text && htmr(PAGE.page_text)} 
                                </span>
                            </div>
                        </div>
                    </div>
                    {PAGE.video_url &&
                        <VideoContainer
                            videoURL={PAGE.video_url}
                            col={"10"}
                        />
                    }
                </section>
            </div>}
        </Wrapper>
    );
}

export default AboutGardenerPage;