import React, {FC} from 'react';
import I18n from "../../../helpers/i18n";
interface Props {}

const CompletedSignUp : FC <Props> = (props) => {
    return (
        <div className="completed-signup">
            <h2>{I18n.t('FORM_SIGN_UP_COMPLETED_TITLE')}</h2>
            <p>{I18n.t('FORM_SIGN_UP_COMPLETED_TEXT')}</p>
        </div>
    );
}

export default CompletedSignUp;