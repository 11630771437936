import React, { FC } from 'react';
import I18n from "../../../../helpers/i18n";
import { interfaceDrop } from '../../../../models/drop';
import UpdateForm from './components/updateForm/updateForm';

interface Props {
}

const UpdateDrop: FC<Props> = (props) => {
    return (
        <section className='drops'>
            <h3>{I18n.t('DROP_UPDATE')}</h3>
            <UpdateForm />
        </section>
    );
}

export default UpdateDrop;
