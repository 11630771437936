import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from '../../helpers/i18n';
import Placeholder from '../../images/placeholder.jpeg';
import InputField from '../../components/elements/inputfield/inputfield';
import Helpers from '../../helpers/functions';
import { getLoginPage, login } from '../../redux/pages';
import API from '../../helpers/api';
import { Link, useHistory } from 'react-router-dom';
import useDebounce from '../../hooks/use-debounce';
import Button from '../../components/elements/button/button';
import { clearLoginStatus, doLogin, login_status, user } from '../../redux/auth';
import useGaTracker from '../../navigation/useGaTracker';

const APIManager = API.instance;

const Validators = Helpers.instance;
interface Props {}

const LoginPage: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(login);

    const [disableForm, setDisableForm] = useState(true);

    const [loaderForm, setLoaderForm] = useState(false);
    const loginStatus = useSelector(login_status);
    const currentUser = useSelector(user);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    useEffect(() => {
        setLoader(true);
        dispatch(getLoginPage());
    }, []);

    useEffect(() => {
        if (loader && PAGE !== '') {
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        if (loginStatus === 'success') {
            setEmailError(false);
            setPasswordError(false);
        } else if (loginStatus != '') {
            setLoaderForm(false);
            setEmailError(true);
            setEmailErrorText(loginStatus);
        }
    }, [loginStatus]);

    useEffect(() => {
        if (loginStatus === '') return;
        if (currentUser.role.name === 'Arborist') {
            history.push('/map');
        } else if (currentUser.role.name === 'Gardener') {
            history.push('/account/drops-request');
        }
    }, [currentUser]);

    const debouncedAccountTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedAccountTerm]);

    const debouncedPasswordTerm = useDebounce(password, 400);
    useEffect(() => {
        if (email !== '') {
            let valEmail = Validators.validateEmail(email);
            setEmailError(valEmail[0]);
            setEmailErrorText(valEmail[1]);
        }
    }, [debouncedPasswordTerm]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let valEmail = Validators.validateString(email);
        let valPassword = Validators.validateRegisterPassword(password);

        if (!valEmail[0]) {
            setEmailError(valEmail[0]);
            setEmailErrorText(valEmail[1]);
        } else {
            return;
        }

        sendForm();
    };

    const sendForm = () => {
        setLoaderForm(true);
        dispatch(doLogin(email, password));
    };

    const checkIfFormIsAllowed = () => {
        if (email !== '' && !emailError && password !== '' && !passwordError) {
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    };

    useEffect(() => {
        checkIfFormIsAllowed();
    }, [email, emailError, password, passwordError]);

    useGaTracker();

    return (
        <Wrapper seo_title={PAGE.page_title} seo_description={PAGE.seo_description}>
            <section
                className="login"
                style={{ backgroundImage: `url(${APIManager.getImageBaseURL(PAGE.page_background_image)})` }}
            >
                {loader ? (
                    <div className="emptystate" />
                ) : (
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <div className="content">
                                        <div className="d-flex flex-column align-items-center mb-4">
                                            <h1 className="h2">{PAGE.page_title}</h1>
                                            <span className="line -small" />
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <InputField
                                                value={email}
                                                onChange={(val) => setEmail(val.target.value)}
                                                label={I18n.t('FORM_EMAIL')}
                                                email
                                                error={emailError}
                                                errorText={emailErrorText}
                                            />
                                            <InputField
                                                value={password}
                                                onChange={(val) => setPassword(val.target.value)}
                                                label={I18n.t('FORM_PASSWORD')}
                                                secure
                                                error={passwordError}
                                                errorText={passwordErrorText}
                                            />
                                            <Button
                                                title={I18n.t('LOGIN')}
                                                fullWidth
                                                loader={loaderForm}
                                                disabled={disableForm}
                                            />
                                        </form>
                                        <Link to={'/sign-up'} className="sign-up-link">
                                            {I18n.t('SIGN_UP_FROM_LOGIN')}
                                        </Link>
                                        <Link to={'/forgot-password'} className="forgot-link">
                                            {I18n.t('FORGOT_PASSWORD')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </Wrapper>
    );
};

export default LoginPage;
