import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from '../../helpers/i18n';
import PageBanner from '../../components/blocks/page-banner/page-banner';
import VideoContainer from '../../components/blocks/video-container/video-container';
import ImageTextRowLeft from '../../components/blocks/image-text-row-left/image-text-row-left';
import ImageTextRowRight from '../../components/blocks/image-text-row-right/image-text-row-right';
import TextButtonContainer from '../../components/blocks/text-button-container/text-button-container';
// import ReviewBlock from '../../components/elements/single-review/single-review';
import Supporter from '../../components/elements/supporter/supporter';
import Button from '../../components/elements/button/button';
import { getHomePage, homepage, getSupporters, supporters } from '../../redux/pages';
import Slider from 'react-slick';
import htmr from 'htmr';
import { Link } from 'react-router-dom';
import API from '../../helpers/api';
import { user } from '../../redux/auth';
import useGaTracker from '../../navigation/useGaTracker';

const APIManager = API.instance;

interface Props {}

const HomePage: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(homepage);
    const supportersData = useSelector(supporters);
    const currentUser = useSelector(user);

    var carouselSettings = {
        dots: false,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    useEffect(() => {
        if (loader && PAGE !== '') {
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getHomePage());
        dispatch(getSupporters());
    }, []);

    useGaTracker();

    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? (
                <div className="emptystate" />
            ) : (
                <div>
                    <PageBanner
                        bannerImage={APIManager.getImageBaseURL(PAGE.banner_image)}
                        bannerTitle={PAGE.banner_title}
                        bannerSubtitle={PAGE.banner_subtitle}
                    />
                    <section className="page">
                        {currentUser.id !== null &&
                        currentUser.id !== undefined &&
                        currentUser.id !== '' &&
                        currentUser.role !== undefined &&
                        currentUser.role.name !== undefined ? (
                            <div className="container mb-5 pb-5">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-12 col-lg-7 text-align-center">
                                        <h2 className="d-block mb-2">
                                            {I18n.t('WELCOME_BACK')}, {currentUser.first_name}!
                                        </h2>
                                        {currentUser.role.name == 'Arborist' && (
                                            <span className="page-text">
                                                {I18n.t('WELCOME_TEXT')} <Link to={'/map'}>{I18n.t('MAP')}</Link>{' '}
                                                {I18n.t('ARBORIST_WELCOME')}
                                            </span>
                                        )}
                                        {currentUser.role.name == 'Gardener' && (
                                            <span className="page-text">
                                                {I18n.t('WELCOME_TEXT')}{' '}
                                                <Link to={'/account/drops-request'}>{I18n.t('YOUR_ACCOUNT')}</Link>{' '}
                                                {I18n.t('GARDENER_WELCOME')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="container">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-12 col-lg-5 d-flex justify-content-between flex-column">
                                        <h2 className="mb-4">{PAGE.left_block_title}</h2>
                                        <span className="page-text -alt min-height">
                                            {PAGE.left_block_text && htmr(PAGE.left_block_text)}
                                        </span>
                                        <div className="d-flex align-items-center mt-3 mt-lg-4 mb-5 flex-wrap">
                                            <Button
                                                title={PAGE.gardener_sign_up_button}
                                                navTo={'/sign-up?type=gardener'}
                                                clear
                                                alt
                                            />
                                            <span className="option-divider">{I18n.t('OR')}</span>
                                            <Button title={PAGE.gardener_login_button} navTo={'/login'} clear alt />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 d-flex justify-content-between flex-column">
                                        <div>
                                            <h2 className="-alt mb-4">{PAGE.right_block_title}</h2>
                                            <span className="page-text min-height">
                                                {PAGE.right_block_text && htmr(PAGE.right_block_text)}
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center mt-3 mt-lg-4 mb-5 flex-wrap">
                                            <Button
                                                title={PAGE.arborist_sign_up_button}
                                                navTo={'/sign-up?type=arborist'}
                                                clear
                                            />
                                            <span className="option-divider">or</span>
                                            <Button title={PAGE.arborist_login_button} navTo={'/login'} clear />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="container">
                            <div className="row d-flex justify-content-between">
                                <h2 className="mb-4">{'Download now the app'}</h2>
                                <div className="col-12 col-lg-5 d-flex justify-content-between flex-column">
                                    <div className="download-app-home">
                                        <a href="https://apps.apple.com/us/app/mulch2garden/id1613558562">
                                            <img
                                                src={
                                                    'https://mulchcms.tecdam.io/assets/1578a251-3b2a-4e14-8169-84edcbd380a3.png?width=220&height=68'
                                                }
                                            ></img>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.tecdam.mulch2garden&hl=es&gl=US">
                                            <img
                                                src={
                                                    'https://mulchcms.tecdam.io/assets/9d9d6d44-ad7c-4aed-97bd-6094e5c555d3?width=220&height=68'
                                                }
                                            ></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <VideoContainer title={PAGE.video_title} videoURL={PAGE.video_url} />
                    </section>
                    <section className="page extra-space">
                        <div className="container-fluid p-0">
                            <ImageTextRowLeft
                                blockImage={APIManager.getImageBaseURL(PAGE.first_block_image)}
                                blockTitle={PAGE.first_block_title}
                                blockText={PAGE.first_block_text}
                                blockUrl={'/about-gardener'}
                                blockUrlText={I18n.t('LEARN_MORE')}
                                altColorLink
                            />
                            <ImageTextRowRight
                                blockImage={APIManager.getImageBaseURL(PAGE.second_block_image)}
                                blockTitle={PAGE.second_block_title}
                                blockText={PAGE.second_block_text}
                                blockUrl={'/about-arborist'}
                                blockUrlText={I18n.t('LEARN_MORE')}
                                altColorTitle
                            />
                        </div>
                    </section>
                    <section className="page">
                        <TextButtonContainer
                            title={PAGE.text_block_title}
                            text={PAGE.text_block_text}
                            buttonUrl={'/about'}
                            buttonUrlText={PAGE.text_block_button}
                        />
                    </section>
                    <section className="page bg-green -incl-padding mb-0">
                        <div className="container mb-5">
                            <div className="row">
                                <div className="col-12">
                                    <h2>{PAGE.supporters_title}</h2>
                                </div>
                            </div>

                            <section className="carousel-container">
                                <Slider {...carouselSettings}>
                                    {supportersData &&
                                        supportersData.length > 0 &&
                                        supportersData.map((item: any) => {
                                            return <Supporter data={item} />;
                                        })}
                                </Slider>
                            </section>
                        </div>
                    </section>
                </div>
            )}
        </Wrapper>
    );
};

export default HomePage;
