import React, { FC } from 'react';
import I18n from "../../../../helpers/i18n";
import green from '../../../../images/marker-full-free-green.png'
import yellow from '../../../../images/marker-full-free-yellow.png'

interface Props {
}

const Legend: FC<Props> = (props) => {
    return (
        <div className='legend'>
            <div className="item">
                <img src={green} alt="Image high priority" />
                <span>{I18n.t('HIGH_PRIORITY')}</span>
            </div>
            <div className="item">
                <img src={yellow} alt="Image normal priority" />
                <span>{I18n.t('NORMAL_PRIORITY')}</span>
            </div>
        </div>
    );
}

export default Legend;