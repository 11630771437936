import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from "../../../helpers/i18n";
import { Link, useHistory, useLocation } from "react-router-dom";

interface Props {
    blockImage: string,
    blockTitle: string,
    blockText: string,
    blockUrl: string,
    blockUrlText: string,
    altColorTitle?: boolean,
    altColorLink?: boolean
}

const ReviewBlock : FC <Props> = (props) => {
    return (
        <div className="row">
            <div className="col-12 col-lg-6 p-0 order-2 order-lg-1">
                <div className="block-text -left bg-green">
                    <div>
                        <h2 className={props.altColorTitle ? "-alt" : ""}>
                            {props.blockTitle}
                        </h2>
                        <span className="page-text">
                            {props.blockText}
                        </span>
                        <Link
                            to={props.blockUrl}
                            className={props.altColorLink ? "-alt" : ""}>
                                {props.blockUrlText}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6 block-image order-1 order-lg-2" style={{backgroundImage: `url(${props.blockImage})`}}/>
        </div>
    );
}

export default ReviewBlock;