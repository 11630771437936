import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../../../helpers/api';
import I18n from "../../../../helpers/i18n";
import { interfaceDrop } from '../../../../models/drop';
import { user } from '../../../../redux/auth';
import { drops } from '../../../../redux/drops';
import DropCardArborist from '../dropCard/dropCardArborist';
import sort_by from '../../../../images/sort_by.svg';

const APIManager = API.instance;
interface Props {
    getDrops: any
}

const DropsPending: FC<Props> = (props) => {
    const currentDrops = useSelector(drops);
    const currentUser = useSelector(user);
    const [idDropUpdating, setIdDropUpdating] = useState(0);
    const [loaderSuccess, setLoaderSuccess] = useState(false);
    const [loaderCancel, setLoaderCancel] = useState(false);
    const [sort, setSort] = useState(true);

    useEffect(() => {
        props.getDrops();
    }, []) 

    const onSuccessful = (id: number) => {
        setIdDropUpdating(id);
        setLoaderSuccess(true);
        APIManager.completeDrop(id)
            .then(res => {
                if (res.status === 200) {
                    props.getDrops();
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoaderSuccess(false);
            }) 
    }

    const onCancelByArborist = (drop: interfaceDrop, cancellation: string) => {
        setIdDropUpdating(drop.id);
        setLoaderCancel(true);
        APIManager.unassignDrop(currentUser.id, drop, cancellation)
            .then(res => {
                if (res.status === 200) {
                    props.getDrops();
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoaderCancel(false);
            }) 
    }

    const conditionPending = (drop: interfaceDrop) => {
        if(drop.arborist === undefined) { return false; }
        if(currentUser.id === '') { return false; }

        return drop.arborist !== null && currentUser.id === drop.arborist.id && drop.drop_status.id === 2
    }

    return (
        <section className='drops'>
            <div className="d-flex justify-content-between">
                <h3>{I18n.t('MENU_DROPS_PENDING')}</h3>
                <img src={sort_by} alt="sort drops" onClick={() => setSort(!sort)} className="img-sort"/>
            </div>
            {
                currentDrops.drops.filter((drop: interfaceDrop) => conditionPending(drop)).length === 0 &&
                <p>{I18n.t('DROP_ZERO')}</p>
            }
            {
                [...currentDrops.drops]
                    .sort((a, b) => (a.id > b.id) ? (sort ? -1 : 1) : ((b.id > a.id) ? (sort ? 1 : -1) : 0))
                    .map((drop: interfaceDrop) => {
                        if (conditionPending(drop)) {
                            return <DropCardArborist 
                                        drop={drop} 
                                        key={drop.id} 
                                        onSuccessful={onSuccessful} 
                                        onCancelByArborist={onCancelByArborist} 
                                        idDropUpdating={idDropUpdating}
                                        loaderSuccess={loaderSuccess}
                                        loaderCancel={loaderCancel} />
                        }
                    })
            }
        </section>
    );
}

export default DropsPending;
