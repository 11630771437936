import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../../../helpers/api';
import I18n from "../../../../helpers/i18n";
import { interfaceDrop } from '../../../../models/drop';
import { user } from '../../../../redux/auth';
import ServiceCardArborist from '../serviceCard/serviceCardArborist';
import sort_by from '../../../../images/sort_by.svg';

const APIManager = API.instance;
interface Props {
}

const RequestPending: FC<Props> = (props) => {
    const [currentRequests, setCurrentRequests] = useState<any>([]);
    const currentUser = useSelector(user);
    const [idDropUpdating, setIdDropUpdating] = useState(0);
    const [loaderSuccess, setLoaderSuccess] = useState(false);
    const [loaderCancel, setLoaderCancel] = useState(false);
    const [sort, setSort] = useState(true);

    useEffect(() => {
        loadServices();
    }, [currentUser])
    
    const loadServices = () => {
        if(currentUser === undefined || currentUser === null) {
            return;
        }
        APIManager.getRequests(currentUser.id)
        .then((res: any) => {
            setCurrentRequests(res.data.data);
        })
        .catch((err: any) => {
        })
    }

    const declinedRequest = (id: number) => {
        setIdDropUpdating(id);
        setLoaderSuccess(true);
        APIManager.serviceDecline(id)
            .then(res => {
                if (res.status === 200) {
                    setCurrentRequests([]);
                    loadServices();
                    setLoaderSuccess(false);
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoaderSuccess(false);
            }) 
    }

    const finishServiceRequest = (request: any, cancellation: string) => {
        setIdDropUpdating(request.id);
        setLoaderCancel(true);
        
        var cancellation = cancellation;

        switch (cancellation) {
            case "Lead was good":
                cancellation = "lead_good";
                break;
        
            case "Provided quote":
                cancellation = "provided_quote";
                break;
            case "Scheduled work":
                cancellation = "scheduled_work";
                break;
            case "Customer declined":
                cancellation = "customer_declined";
                break;
            default:
                break;
        }

        APIManager.serviceCompleted(request.id, cancellation)
            .then(res => {
                if (res.status === 200) {
                    setCurrentRequests([]);
                    loadServices();
                    setLoaderSuccess(false);
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoaderCancel(false);
            }) 
    }

    const conditionPending = (drop: interfaceDrop) => {
        return drop.arborist !== null && currentUser.id === drop.arborist.id && drop.drop_status.id === 2
    }

    return (
        <section className='drops'>
            <div className="d-flex justify-content-between">
                <h3>{I18n.t('MENU_SERVICE_REQUESTS')}</h3>
                <img src={sort_by} alt="sort drops" onClick={() => setSort(!sort)} className="img-sort"/>
            </div>
            {
                currentRequests.length === 0 &&
                <p>{I18n.t('SERVICE_ZERO')}</p>
            }
            {
                [...currentRequests]
                    .sort((a, b) => (a.id > b.id) ? (sort ? -1 : 1) : ((b.id > a.id) ? (sort ? 1 : -1) : 0))
                    .map((request: any) => {
                        return <ServiceCardArborist
                                    request={request}
                                    drop={request.drop}
                                    key={request.id} 
                                    declinedRequest={declinedRequest} 
                                    finishServiceRequest={finishServiceRequest} 
                                    loaderSuccess={loaderSuccess}
                                    loaderCancel={loaderCancel} />
                    })
            }
        </section>
    );
}

export default RequestPending;
