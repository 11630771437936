import React, { FC } from 'react';
import DocumentMeta from 'react-document-meta';
import Header from '../header/header';
import Footer from '../footer/footer';
import I18n from "../../helpers/i18n";

interface Props {
    seo_title?: string,
    seo_description?: string
}

const Wrapper : FC<Props> = (props) => {
    const meta = {
        title: props.seo_title ? props.seo_title + " - " + I18n.t("SITE_NAME") : I18n.t('DEFAULT_SEO_TITLE') + " - " + I18n.t("SITE_NAME"),
        description: props.seo_description ? props.seo_description : I18n.t('DEFAULT_SEO_DESCRIPTION'),
        canonical: window.location.href,
        meta: {
            charset: 'utf-8',
        }
    };

    return (
        <DocumentMeta {...meta}>
            <Header/>
            <div className={"content-wrapper"}>
                {props.children}
            </div>
            <Footer/>
        </DocumentMeta>
    );
}
export default Wrapper;