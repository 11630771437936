import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';
const APIManager = API.instance;

interface thisState {
    homepage: any;
    faq: any;
    login: any;
    signup: any;
    privacy: any;
    privacy_ca: any;
    cookie: any;
    terms: any;
    termsUse: any;
    about: any;
    about_gardener: any;
    about_arborist: any;
    my_account: any;
    validation_account: any;
    forgot_password: any;
    error_payment: any;
    supporters: any;
    donation: any;
}

const initialState: thisState = {
    homepage: '',
    faq: '',
    login: '',
    signup: '',
    privacy: '',
    privacy_ca: '',
    cookie: '',
    terms: '',
    termsUse: '',
    about: '',
    about_arborist: '',
    about_gardener: '',
    my_account: '',
    validation_account: '',
    forgot_password: '',
    error_payment: '',
    supporters: '',
    donation: '',
};

export const thisSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        setHomePage: (state, action: PayloadAction<[]>) => {
            state.homepage = action.payload;
        },
        setFAQPage: (state, action: PayloadAction<[]>) => {
            state.faq = action.payload;
        },
        setLoginPage: (state, action: PayloadAction<[]>) => {
            state.login = action.payload;
        },
        setSignUpPage: (state, action: PayloadAction<[]>) => {
            state.signup = action.payload;
        },
        setPrivacy: (state, action: PayloadAction<[]>) => {
            state.privacy = action.payload;
        },
        setPrivacyCA: (state, action: PayloadAction<[]>) => {
            state.privacy_ca = action.payload;
        },
        setCookie: (state, action: PayloadAction<[]>) => {
            state.cookie = action.payload;
        },
        setTerms: (state, action: PayloadAction<[]>) => {
            state.terms = action.payload;
        },
        setTermsUse: (state, action: PayloadAction<[]>) => {
            state.termsUse = action.payload;
        },
        setAbout: (state, action: PayloadAction<[]>) => {
            state.about = action.payload;
        },
        setAboutGardener: (state, action: PayloadAction<[]>) => {
            state.about_gardener = action.payload;
        },
        setAboutArborist: (state, action: PayloadAction<[]>) => {
            state.about_arborist = action.payload;
        },
        setDonation: (state, action: PayloadAction<[]>) => {
            state.donation = action.payload;
        },
        setMyAccount: (state, action: PayloadAction<[]>) => {
            state.my_account = action.payload;
        },
        setValidateAccount: (state, action: PayloadAction<[]>) => {
            state.validation_account = action.payload;
        },
        setForgotPassword: (state, action: PayloadAction<[]>) => {
            state.forgot_password = action.payload;
        },
        setErrorPayment: (state, action: PayloadAction<[]>) => {
            state.error_payment = action.payload;
        },
        setSupporters: (state, action: PayloadAction<[]>) => {
            state.supporters = action.payload;
        },
    },
});
export const {
    setHomePage,
    setFAQPage,
    setLoginPage,
    setPrivacy,
    setPrivacyCA,
    setCookie,
    setTerms,
    setAbout,
    setAboutArborist,
    setAboutGardener,
    setSignUpPage,
    setMyAccount,
    setValidateAccount,
    setForgotPassword,
    setErrorPayment,
    setSupporters,
    setTermsUse,
    setDonation,
} = thisSlice.actions;

export const getHomePage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Homepage', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setHomePage(data));
        }
    });
};

export const getFAQPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/How_it_works', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setFAQPage(data));
        }
    });
};

export const getLoginPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Login', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setLoginPage(data));
        }
    });
};

export const getTermsPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Terms_and_conditions', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setTerms(data));
        }
    });
};

export const getTermsUsePage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/TermsUse', '').then((data) => {
        console.log(data);
        if (data !== null && data !== undefined) {
            dispatch(setTermsUse(data));
        }
    });
};

export const getPrivacyPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Privacy_statement', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setPrivacy(data));
        }
    });
};

export const getPrivacyCAPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Privacy_statement_CA', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setPrivacyCA(data));
        }
    });
};

export const getCookiePage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Cookie_policy', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setCookie(data));
        }
    });
};

export const getAboutPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/About_us?fields=*.*.*', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setAbout(data));
        }
    });
};

export const getDonationPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/donation?fields=*.*.*', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setDonation(data));
        }
    });
};

export const getAboutArboristPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/About_arborist', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setAboutArborist(data));
        }
    });
};

export const getAboutGardenerPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/About_gardener', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setAboutGardener(data));
        }
    });
};

export const getSignUpPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/Sign_up', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setSignUpPage(data));
        }
    });
};

export const getMyAccountPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/My_account', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setMyAccount(data));
        }
    });
};

export const getValidationAccountPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/validation_account', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setValidateAccount(data));
        }
    });
};

export const getForgotPasswordPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/forgot_password', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setForgotPassword(data));
        }
    });
};

export const getErrorPaymentPage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/error_payment', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setErrorPayment(data));
        }
    });
};

export const getSupporters = (): AppThunk => (dispatch) => {
    APIManager.get('/items/supporters', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setSupporters(data));
        }
    });
};

export const homepage = (state: RootState) => state.pages.homepage;
export const faq = (state: RootState) => state.pages.faq;
export const login = (state: RootState) => state.pages.login;
export const privacy = (state: RootState) => state.pages.privacy;
export const privacy_ca = (state: RootState) => state.pages.privacy_ca;
export const cookie = (state: RootState) => state.pages.cookie;
export const terms = (state: RootState) => state.pages.terms;
export const termsUse = (state: RootState) => state.pages.termsUse;
export const about = (state: RootState) => state.pages.about;
export const about_arborist = (state: RootState) => state.pages.about_arborist;
export const about_gardener = (state: RootState) => state.pages.about_gardener;
export const signup = (state: RootState) => state.pages.signup;
export const my_account = (state: RootState) => state.pages.my_account;
export const validation_account = (state: RootState) => state.pages.validation_account;
export const forgot_password = (state: RootState) => state.pages.forgot_password;
export const error_payment = (state: RootState) => state.pages.error_payment;
export const supporters = (state: RootState) => state.pages.supporters;
export const donation = (state: RootState) => state.pages.donation;

export default thisSlice.reducer;
