import React, { FC, useState } from 'react';
import Button from '../../../../components/elements/button/button';
import API from '../../../../helpers/api';
import I18n from "../../../../helpers/i18n";
import { interfaceDrop } from '../../../../models/drop';
import MapDrop from '../mapDrop/mapDrop';

import Select from '../../../../components/elements/select/select';

const APIManager = API.instance;
interface Props {
    request: any,
    drop: interfaceDrop,
    declinedRequest?: any,
    finishServiceRequest?: any,
    idDropUpdating?: number,
    loaderSuccess?: boolean,
    loaderCancel?: boolean
}

const ServiceCardArborist: FC<Props> = (props) => {
    const [textCopied, setTextCopied] = useState(false);
    const [showCancelInput, setShowCancelInput] = useState(false);
    const [cancelInput, setCancelInput] = useState('');
    const [answer, setAnswer] = useState("Lead was good");

    const formatDate = (date: string) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    var possibleAnswerText = [
        "Lead was good",
        "Provided quote",
        "Scheduled work",
        "Customer declined"
    ]

    const readableServices = () => {
        if(props.request.needed_service === undefined || props.request.needed_service === null) {
            return '';
        }
        let jsonServices = JSON.parse(props.request.needed_service);
        var services = [];
        if(jsonServices.need_trees_pruned) {
            services.push(I18n.t('SERVICE_NEED_TREES_PRUNED'));
        }
        if(jsonServices.need_trees_planted) {
            services.push(I18n.t('SERVICE_NEED_TREES_PLANTED'));
        }
        if(jsonServices.need_trees_removed) {
            services.push(I18n.t('SERVICE_NEED_TREES_REMOVED'));
        }
        if(jsonServices.other_gardening_services) {
            services.push(I18n.t('SERVICE_OTHER_SERVICES'));
        }
        return services.join(', ');
    }

    return (
        <div className='drop-card'>
            <div className="header-card">
                <div><span>{I18n.t('DROP_ORDER_NO')}: </span>{props.request.id}</div>
            </div>
            <div className="body-card">
                <div className="user-info">
                    <h4>{I18n.t('DROP_GARDENER')}</h4>
                    <div>
                        <span>{I18n.t('DROP_NAME')}: </span> 
                        {props.drop.user_created.first_name} {props.drop.user_created.last_name}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_PHONE')}: </span>
                        <a href={`tel:${props.drop.user_created.phone}`}>{props.drop.user_created.phone}</a>
                    </div>
                    <div>
                        <span>{I18n.t('DROP_EMAIL')}: </span> 
                        <a href={`mailto:${props.drop.user_created.email}`}>{props.drop.user_created.email}</a>
                    </div>
                </div>
                <div className="drop-info">
                    <h4>{I18n.t('SERVICE_INFO')}</h4>
                    <div>
                        <span>{I18n.t('DROP_DATE')}: </span>
                        {formatDate(props.request.date_created)}
                    </div>
                    <div>
                        <span>{I18n.t('SERVICE_SERVICES')}: </span>
                        {readableServices()}
                    </div>
                    <div>
                        <span>{I18n.t('SERVICE_NUMBER_TREES')}: </span>
                        {props.request.num_trees}
                    </div>
                    <div>
                        <span>{I18n.t('SERVICE_TYPETREES')}: </span>
                        {props.request.type_trees}
                    </div>
                    <div>
                        <span>{I18n.t('SERVICE_TIMEFRAME')}: </span>
                        {props.request.time_frame}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_DESCRIPTION')}: </span>
                        {props.request.description}
                    </div>
                    {
                        props.drop.drop_status.id === 2 &&
                        <MapDrop lat={props.drop.latitude} lng={props.drop.longitude} />
                    }
                </div>

                <div className="buttons-card">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Button
                                fullWidth small
                                title={I18n.t('SERVICE_DECLINE')}
                                onClick={() => props.declinedRequest(props.request.id)}
                                loader={props.drop.id === props.idDropUpdating && props.loaderSuccess} />
                        </div>
                        <div className="col-12 col-md-6">
                            <Button
                                disabled={showCancelInput}
                                fullWidth small
                                title={I18n.t('SERVICE_ACCEPT')}
                                onClick={() => setShowCancelInput(true)} />
                        </div>
                    </div>
                </div>
                {
                    showCancelInput &&
                    <>
                        <Select
                            value={answer}
                            array={possibleAnswerText}
                            onChange={(val: any) => setAnswer(val.target.value)}
                            label={I18n.t('FORM_REASON_ACCEPTATION')}
                        />
                        <Button
                            fullWidth small
                            title={I18n.t('FORM_SEND_ACCEPTATION')}
                            onClick={() => props.finishServiceRequest(props.request, answer)} 
                            loader={props.drop.id === props.idDropUpdating && props.loaderCancel} />
                    </>
                }
            </div>

        </div>
    );
}

export default ServiceCardArborist;
