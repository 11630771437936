import React, { FC } from 'react';
import I18n from '../../../../helpers/i18n';
import gardener from '../../../../images/account-type-1.png';
import { interfaceDrop } from '../../../../models/drop';
import { InfoWindow } from '@react-google-maps/api';
import verified from '../../../../images/verified.svg';

const apiUrl = 'https://mulchcms.tecdam.io';

interface Props {
    drop: interfaceDrop;
    onCloseInfoWindow: any;
    onAssignDrop: any;
}

const InfoWindowComponent: FC<Props> = (props) => {
    return (
        <InfoWindow
            onCloseClick={props.onCloseInfoWindow}
            position={{
                lat: props.drop.latitude,
                lng: props.drop.longitude,
            }}
            options={{ pixelOffset: new google.maps.Size(0, -45) }}
        >
            <div className="info-window">
                <div className="avatar" style={{ backgroundImage: `url(${gardener})` }}></div>
                <span className="name">
                    {props.drop.user_created.first_name} {props.drop.user_created.last_name}
                </span>
                <div className="verified">
                    <span>{props.drop.user_created.address}</span>
                </div>
                {props.drop.user_created.verified_address ? (
                    <div className="verified">
                        <img src={verified} alt={I18n.t('USER_VERIFIED')} />
                        <span>{I18n.t('USER_VERIFIED')}</span>
                    </div>
                ) : (
                    <div className="verified">
                        <span>{I18n.t('USER_NOT_VERIFIED')}</span>
                    </div>
                )}
                <span className="highlight">
                    {props.drop.drop_type.type} | {props.drop.drop_size.size}
                </span>
                <div className="divider" />
                <span className="text dark">{I18n.t('INSTRUCTIONS_DROP')}:</span>
                <span className="text">{props.drop.description}</span>
                {props.drop.photo !== null && (
                    <a href={apiUrl + '/assets/' + props.drop.photo.filename_disk} target="_blank">
                        <img className="dropImg" src={apiUrl + '/assets/' + props.drop.photo.filename_disk}></img>
                    </a>
                )}
                <a onClick={props.onAssignDrop} className="btn-filled button -small">
                    {I18n.t('ASSIGN_DROP')}
                </a>
            </div>
        </InfoWindow>
    );
};

export default InfoWindowComponent;
