import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { user, setLoginStatus, setUser, doLogout } from '../redux/auth';
import HomePage from '../screens/home/home';
import HowItWorks from '../screens/how-it-works/how-it-works';
import LoginPage from '../screens/login/login';
import SignupPage from '../screens/sign-up/sign-up';
import MapPage from '../screens/map/map';
import AdminPage from '../screens/admin/admin';
import AboutPage from '../screens/about/about';
import DonationPage from '../screens/donation/donation';
import AccountPage from '../screens/account/account';
import TextPage from '../screens/text-page/text-page';
import AboutArboristPage from '../screens/about-arborist/about-arborist';
import AboutGardenerPage from '../screens/about-gardener/about-gardener';
import ValidateAccountPage from '../screens/validate-account/validateAccount';
import ForgotPasswordPage from '../screens/forgot-password/forgotPassword';
import NewPasswordPage from '../screens/new-password/newPassword';
import ErrorPaymentPage from '../screens/error-payment/errorPayment';
import PageBehaviour from './page_behaviour';
import PrivateRoute from './protectedRoute';
import API from '../helpers/api';
import Cookie from '../helpers/cookie';
import NoMatchPage from '../screens/404/404';

const APIManager = API.instance;
const cookie = new Cookie();

const Navigation: FC = (props) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(user);
    const [protectedMap, setProtectedMap] = useState({
        isAuthenticated: null as null | boolean,
        authenticationPath: '/login',
    });
    const [protectedAccount, setProtectedAccount] = useState({
        isAuthenticated: null as null | boolean,
        authenticationPath: '/login',
    });

    useEffect(() => {
        const role = cookie.getCookie('role');
        const token = cookie.getCookie('token');

        if (role === null || token === null) {
            dispatch(doLogout());
        } else {
            APIManager.getProfile()
                .then((res) => {
                    dispatch(setUser(res.data.data));
                })
                .catch((err) => {
                    console.log(err);
                });
            dispatch(setLoginStatus('success'));
        }
    }, []);

    useEffect(() => {
        const role = cookie.getCookie('role');
        if (role === 'Arborist') {
            setProtectedMap({
                ...protectedMap,
                isAuthenticated: true,
            });
        } else {
            setProtectedMap({
                ...protectedMap,
                isAuthenticated: false,
            });
        }

        if (role === 'Gardener' || role === 'Arborist') {
            setProtectedAccount({
                ...protectedAccount,
                isAuthenticated: true,
            });
        } else {
            setProtectedAccount({
                ...protectedAccount,
                isAuthenticated: false,
            });
        }
    }, [currentUser]);

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <PageBehaviour />
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/faq" component={HowItWorks} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/sign-up" component={SignupPage} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/about-gardener" component={AboutGardenerPage} />
                <Route exact path="/about-arborist" component={AboutArboristPage} />
                <Route exact path="/donation" component={DonationPage} />
                <Route exact path="/terms-use" component={TextPage} />
                <Route exact path="/terms-of-service" component={TextPage} />
                <Route exact path="/cookie-policy" component={TextPage} />
                <Route exact path="/privacy-policy" component={TextPage} />
                <Route exact path="/privacy-policy-ca" component={TextPage} />
                <Route exact path="/validate-account/:salt" component={ValidateAccountPage} />
                <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                <Route exact path="/new-password/:token" component={NewPasswordPage} />
                <Route exact path="/error-payment" component={ErrorPaymentPage} />
                <Route exact path="/my-account" render={() => <Redirect to="/account/drops-request" />} />
                <PrivateRoute {...protectedMap} exact path="/map" component={MapPage} />
                <PrivateRoute {...protectedMap} exact path="/admin" component={AdminPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/create" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/update-drop/:id" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/request-service/:id" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/drops-request" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/drops-received" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/drops-pending" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/requests-pending" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/drops-completed" component={AccountPage} />
                <PrivateRoute {...protectedAccount} exact path="/account/details" component={AccountPage} />
                <Route component={NoMatchPage} />
            </Switch>
        </Router>
    );
};

export default Navigation;
