import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../../helpers/i18n';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Helpers from '../../../helpers/functions';
import API from '../../../helpers/api';
import InputField from '../../../components/elements/inputfield/inputfield';
import Checkbox from '../../../components/elements/checkbox/checkbox';
import Select from '../../../components/elements/select/select';
import useDebounce from '../../../hooks/use-debounce';
import { statesUSA } from '../../../helpers/statesUSA';
import useRecaptcha from '../../../hooks/use-recaptcha';
import Button from '../../../components/elements/button/button';

const APIManager = API.instance;
const Validators = Helpers.instance;
const SITE_KEY = '6Ld4agoeAAAAAF1nP1roB7qZaB-D1mxRipDGgSfr';
interface Props {
    role: number;
    signUpCompleted: any;
}

const SignUpForm: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const useCaptcha = useRecaptcha(SITE_KEY);
    const [loaderForm, setLoaderForm] = useState(false);

    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [verifyEmail, setVerifyEmail] = useState('');
    const [verifyEmailError, setVerifyEmailError] = useState(false);
    const [verifyEmailErrorText, setVerifyEmailErrorText] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState(false);
    const [zipcodeErrorText, setZipcodeErrorText] = useState('');

    const [state, setState] = useState('');
    const [stateError, setStateError] = useState(false);
    const [stateErrorText, setStateErrorText] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorText, setPhoneErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    const [acceptedLead, setAcceptedLead] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    const [formError, setFormError] = useState(I18n.t('FORM_SEND_ERROR'));

    const debouncedFirstNameTerm = useDebounce(firstName, 400);
    useEffect(() => {
        if (firstName !== '') {
            let valField = Validators.validateString(firstName);
            setFirstNameError(valField[0]);
            setFirstNameErrorText(valField[1]);
        }
    }, [debouncedFirstNameTerm]);

    const debouncedLastNameTerm = useDebounce(lastName, 400);
    useEffect(() => {
        if (lastName !== '') {
            let valField = Validators.validateString(lastName);
            setLastNameError(valField[0]);
            setLastNameErrorText(valField[1]);
        }
    }, [debouncedLastNameTerm]);

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    const debouncedEmailVerifyTerm = useDebounce(verifyEmail, 400);
    useEffect(() => {
        if (verifyEmail === '') return;
        if (email === verifyEmail) {
            setVerifyEmailError(false);
            setVerifyEmailErrorText('');
        } else {
            setVerifyEmailError(true);
            setVerifyEmailErrorText(I18n.t('FORM_ERROR_EMAIL_NOT_SAME'));
        }
    }, [debouncedEmailVerifyTerm, debouncedEmailTerm]);

    const debouncedAddressTerm = useDebounce(address, 400);
    useEffect(() => {
        if (address !== '') {
            let valField = Validators.validateString(address);
            setAddressError(valField[0]);
            setAddressErrorText(valField[1]);
        }
    }, [debouncedAddressTerm]);

    const debouncedCityTerm = useDebounce(city, 400);
    useEffect(() => {
        if (city !== '') {
            let valField = Validators.validateString(city);
            setCityError(valField[0]);
            setCityErrorText(valField[1]);
        }
    }, [debouncedCityTerm]);

    const debouncedZipcodeTerm = useDebounce(zipcode, 400);
    useEffect(() => {
        if (zipcode !== '') {
            let valField = Validators.validateZipCode(zipcode);
            setZipcodeError(valField[0]);
            setZipcodeErrorText(valField[1]);
        }
    }, [debouncedZipcodeTerm]);

    const debouncedStateTerm = useDebounce(state, 400);
    useEffect(() => {
        if (state !== '') {
            let valField = Validators.validateString(state);
            setStateError(valField[0]);
            setStateErrorText(valField[1]);
        }
    }, [debouncedStateTerm]);

    const debouncedPasswordTerm = useDebounce(password, 400);
    useEffect(() => {
        if (password !== '') {
            let valField = Validators.validateCreateNewPassword(password);
            setPasswordError(valField[0]);
            setPasswordErrorText(valField[1]);
        }
    }, [debouncedPasswordTerm]);

    const debouncedConfirmPasswordTerm = useDebounce(confirmPassword, 400);
    useEffect(() => {
        if (password === confirmPassword) {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');
        } else {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText(I18n.t('FORM_ERROR_PASSWORD_NOT_SAME'));
        }
    }, [debouncedConfirmPasswordTerm]);

    const checkIfRegistrationIsAllowed = () => {
        if (
            firstName !== '' &&
            !firstNameError &&
            lastName !== '' &&
            !lastNameError &&
            email !== '' &&
            !emailError &&
            verifyEmail !== '' &&
            !verifyEmailError &&
            address !== '' &&
            !addressError &&
            city !== '' &&
            !cityError &&
            zipcode !== '' &&
            !zipcodeError &&
            state !== '' &&
            !stateError &&
            password !== '' &&
            !passwordError &&
            confirmPassword !== '' &&
            !confirmPasswordError &&
            acceptedTerms &&
            phone !== '' &&
            !phoneError
        ) {
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    };

    useEffect(() => {
        checkIfRegistrationIsAllowed();
    }, [
        firstName,
        firstNameError,
        lastName,
        lastNameError,
        email,
        emailError,
        verifyEmail,
        verifyEmailError,
        address,
        addressError,
        city,
        cityError,
        zipcode,
        zipcodeError,
        password,
        passwordError,
        confirmPassword,
        confirmPasswordError,
        acceptedTerms,
        phone,
        phoneError,
    ]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (disableForm) return;
        setLoaderForm(true);
        setErrorUpdateForm(false);

        // @ts-ignore
        window.grecaptcha.ready(() => {
            // @ts-ignore
            window.grecaptcha
                .execute(SITE_KEY, { action: 'submit' })
                .then((token: string) => {
                    APIManager.validateRecaptcha(token)
                        .then((res) => {
                            if (res.data.success) {
                                registerUser();
                            } else {
                                setErrorUpdateForm(true);
                                setLoaderForm(false);
                            }
                        })
                        .catch((err) => {
                            setErrorUpdateForm(true);
                            setLoaderForm(false);
                        });
                })
                .catch((token: string) => {
                    setErrorUpdateForm(true);
                    setLoaderForm(false);
                });
        });
    };

    const registerUser = () => {
        const role = props.role === 1 ? '922eb513-c49c-497d-a674-9a46bd9e82d1' : 'e270fb9e-2b0d-4a9f-99df-99efe54d34c5';

        APIManager.signUp(
            role,
            firstName,
            lastName,
            email,
            password,
            address,
            city,
            zipcode,
            state,
            phone,
            acceptedLead
        )
            .then((res) => {
                if (res.status === 204 || res.status === 200) {
                    setLoaderForm(false);
                    props.signUpCompleted();
                } else {
                    if (res.data !== null && res.data.errors !== null && res.data.errors.length > 0) {
                        if (res.data.errors[0].message === 'Field "email" has to be unique.') {
                            setFormError(I18n.t('FORM_SEND_EMAIL_EXISTS'));
                        } else {
                            setFormError(I18n.t('FORM_SEND_ERROR'));
                        }
                    } else {
                        setFormError(I18n.t('FORM_SEND_ERROR'));
                    }
                    setErrorUpdateForm(true);
                    setLoaderForm(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setErrorUpdateForm(true);
                setLoaderForm(false);
            });
    };

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <InputField
                value={firstName}
                onChange={(val) => setFirstName(val.target.value)}
                label={I18n.t('FORM_FIRST_NAME')}
                error={firstNameError}
                errorText={firstNameErrorText}
            />
            <InputField
                value={lastName}
                onChange={(val) => setLastName(val.target.value)}
                label={I18n.t('FORM_LAST_NAME')}
                error={lastNameError}
                errorText={lastNameErrorText}
            />
            <InputField
                value={email}
                onChange={(val) => setEmail(val.target.value)}
                label={I18n.t('FORM_EMAIL')}
                email
                error={emailError}
                errorText={emailErrorText}
            />
            <InputField
                value={verifyEmail}
                onChange={(val) => setVerifyEmail(val.target.value)}
                label={I18n.t('FORM_VERIFY_EMAIL')}
                email
                error={verifyEmailError}
                errorText={verifyEmailErrorText}
            />
            <InputField
                value={address}
                onChange={(val) => setAddress(val.target.value)}
                label={I18n.t('FORM_ADDRESS')}
                error={addressError}
                errorText={addressErrorText}
            />
            <InputField
                value={city}
                onChange={(val) => setCity(val.target.value)}
                label={I18n.t('FORM_CITY')}
                error={cityError}
                errorText={cityErrorText}
            />
            <InputField
                value={zipcode}
                onChange={(val) => setZipcode(val.target.value)}
                label={I18n.t('FORM_ZIPCODE')}
                error={zipcodeError}
                errorText={zipcodeErrorText}
            />
            <Select
                value={state}
                array={statesUSA}
                onChange={(val: any) => setState(val.target.value)}
                label={I18n.t('FORM_STATE')}
                error={stateError}
                errorText={stateErrorText}
            />
            <InputField
                value={phone}
                onChange={(val) => setPhone(val.target.value)}
                label={I18n.t('FORM_PHONE')}
                error={phoneError}
                errorText={phoneErrorText}
            />
            <InputField
                value={password}
                onChange={(val) => setPassword(val.target.value)}
                label={I18n.t('FORM_PASSWORD')}
                secure
                error={passwordError}
                errorText={passwordErrorText}
                autocomplete={'new-password'}
            />
            <InputField
                value={confirmPassword}
                onChange={(val) => setConfirmPassword(val.target.value)}
                label={I18n.t('FORM_REPEAT_PASSWORD')}
                secure
                error={confirmPasswordError}
                errorText={confirmPasswordErrorText}
                autocomplete={'new-password'}
            />

            <div className="accept-terms-block">
                {props.role === 2 && (
                    <Checkbox
                        onChange={(e: any) => setAcceptedLead(e)}
                        checked={acceptedLead}
                        customLabel
                        label={
                            <div className="custom-content">
                                <span>{I18n.t('SIGNUP_TERMS_LEAD')}</span>
                            </div>
                        }
                        name={'terms_lead'}
                    />
                )}
                <Checkbox
                    onChange={(e: any) => setAcceptedTerms(e)}
                    checked={acceptedTerms}
                    customLabel
                    label={
                        <div className="custom-content">
                            <span>{I18n.t('SIGNUP_TERMS')}</span>
                            <Link target="_blank" to={'/privacy-policy'} className="">
                                {I18n.t('MENU_PRIVACY')}
                            </Link>
                            <span>{I18n.t('AND')}</span>
                            <Link target="_blank" to={'/terms-of-service'} className="">
                                {I18n.t('MENU_TERMS')}
                            </Link>
                        </div>
                    }
                    name={'terms'}
                />
            </div>
            <Button title={I18n.t('BTN_CREATE_ACCOUNT')} fullWidth loader={loaderForm} disabled={disableForm} />
            {errorUpdateForm && <div className="error-send-form">{formError}</div>}
        </form>
    );
};

export default SignUpForm;
