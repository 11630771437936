import React, { useState, FC, useEffect } from 'react';
import axios from 'axios';
import DirectusSDK from '@directus/sdk-js';
import localforage from 'localforage';
import Cookie from './cookie';
import { setupCache } from 'axios-cache-adapter';
import jwt_decode from 'jwt-decode';
import { statesUSA } from './statesUSA';
import { interfaceDrop } from '../models/drop';

const originUrl = 'https://mulch2garden.com';
const apiUrl = 'https://mulchcms.tecdam.io';
const apiUser = 'api@mulch2garden.com';
const apiPassword = '5r3gGardenggSNE^9ZEk#$Mulch';
const cookie = new Cookie();

const cache = setupCache({
    maxAge: 5 * 60 * 1000,
    exclude: { query: false },
});

const api = axios.create({
    adapter: cache.adapter,
});

api.interceptors.request.use(
    (config) => {
        const token = cookie.getCookie('token-mulch');

        if (config !== undefined) {
            if (token !== null) {
                config.headers.common['Authorization'] = 'Bearer ' + token;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            cookie.deleteCookie('token-mulch');
            cookie.deleteCookie('role');

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = cookie.getCookie('refresh_token');

            return new Promise(function (resolve, reject) {
                if (refreshToken !== null || refreshToken !== undefined) {
                    axios
                        .post(`${apiUrl}/auth/refresh`, { refresh_token: refreshToken })
                        .then((response) => {
                            const token = response.data.data.access_token;
                            const refreshToken = response.data.data.refresh_token;
                            cookie.setCookie('token-mulch', token, 30);
                            cookie.setCookie('refresh_token', refreshToken, 30);
                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            API.instance.storedJwt = token;

                            processQueue(null, token);
                            resolve(axios(originalRequest));
                        })
                        .catch((err) => {
                            const credentials = { email: apiUser, password: apiPassword };
                            axios
                                .post(`${apiUrl}/auth/login`, credentials)
                                .then((resAuth) => {
                                    const token = resAuth.data.data.access_token;
                                    const refreshToken = resAuth.data.data.refresh_token;
                                    cookie.setCookie('token-mulch', token, 30);
                                    cookie.setCookie('refresh_token', refreshToken, 30);

                                    processQueue(null, token);
                                    resolve(axios(originalRequest));
                                })
                                .catch((err) => {
                                    isRefreshing = false;
                                })
                                .finally(() => {
                                    isRefreshing = false;
                                });
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                }
            });
        }

        return error.response;
    }
);

export default class API {
    static instance: API = new API();

    directus = new DirectusSDK(apiUrl, { auth: { storage: localforage, mode: 'json' } });
    storedJwt = cookie.getCookie('token-mulch');
    jwt = null;
    fetchError = null;
    loggedIn = false;
    gettingToken = false;
    tokenPromise = new Promise<string>((resolve, reject) => {});

    isUserLoggedIn() {
        return new Promise<boolean>((resolve) => {
            try {
                let token: string = cookie.getCookie('token-mulch') ?? '';
                let decodedBody: any = jwt_decode(token, { header: false });
                let id = decodedBody['id'];
                if (id === undefined || id === null) {
                    resolve(false);
                } else {
                    resolve(id);
                }
            } catch (err) {
                resolve(false);
            }
        });
    }

    getJwt = async () => {
        if (this.gettingToken) {
            return;
        }
        this.gettingToken = true;
        const credentials = { email: apiUser, password: apiPassword };

        const { data } = await api.post(`${apiUrl}/auth/login`, credentials);
        cookie.setCookie('token-mulch', data.data.token, 30);
        this.gettingToken = false;
        this.jwt = data.data.token;
        this.storedJwt = data.data.token;

        return data.data.token;
    };

    refreshJwt = async () => {
        try {
            if (this.storedJwt === null) {
                API.instance.getJwt();
                return;
            }
            const { data } = await api.post(`${apiUrl}/auth/refresh`, { token: this.storedJwt });
            cookie.setCookie('token-mulch', data.data.token, 30);
            this.storedJwt = data.data.token;
            this.jwt = data.data.token;
            return data.data.token;
        } catch (err) {
            this.storedJwt = null;
            cookie.deleteCookie('token-mulch');
            return null;
        }
    };

    updateJwt() {
        if (this.gettingToken) {
            return this.tokenPromise;
        }
        this.gettingToken = true;
        this.tokenPromise = new Promise<string>((resolve) => {
            if (this.storedJwt === null) {
                return this.apiUserAuthenticate();
            }
            axios
                .post(`${apiUrl}/auth/refresh`, { token: this.storedJwt })
                .then((res) => {
                    if (res === undefined || res.status === 404) {
                        return this.apiUserAuthenticate();
                    } else {
                        this.gettingToken = false;
                        cookie.setCookie('token-mulch', res.data.data.token, 30);
                        this.storedJwt = res.data.data.token;
                        resolve(res.data.data.token);
                    }
                })
                .catch((err) => {
                    return this.apiUserAuthenticate();
                });
        });

        return this.tokenPromise;
    }

    apiUserAuthenticate() {
        if (this.gettingToken) {
            return this.tokenPromise;
        }
        this.gettingToken = true;
        this.tokenPromise = new Promise<string>((resolve) => {
            cookie.deleteCookie('token-mulch');

            const credentials = { email: apiUser, password: apiPassword };
            api.post(`${apiUrl}/auth/login`, credentials)
                .then((resAuth) => {
                    this.gettingToken = false;
                    if (resAuth.status === 404) {
                        resolve('');
                    } else {
                        const token = resAuth.data.data.access_token;
                        const refreshToken = resAuth.data.data.refresh_token;
                        cookie.setCookie('token-mulch', token, 30);
                        cookie.setCookie('refresh_token', refreshToken, 30);

                        this.storedJwt = token;
                        resolve(token);
                    }
                })
                .catch((err) => {
                    this.gettingToken = false;
                    resolve('');
                });
        });

        return this.tokenPromise;
    }

    post = async (path: string, body?: any, urlencoded?: boolean) => {
        try {
            var parsedBody = {};
            if (body != null && body != undefined) {
                if (urlencoded) {
                    parsedBody = new URLSearchParams(body);
                    api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                } else {
                    parsedBody = JSON.stringify(body);
                    api.defaults.headers.post['Content-Type'] = 'application/json';
                }
            }
            const { data } = await api.post(`${apiUrl}` + path, parsedBody);
            this.fetchError = null;
            if (data === undefined || data === '' || data === null) {
                return { success: true };
            }
            return data;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    patch = async (path: string, body?: any, urlencoded?: boolean) => {
        try {
            var parsedBody = {};
            if (body != null && body != undefined) {
                if (urlencoded) {
                    parsedBody = new URLSearchParams(body);
                } else {
                    parsedBody = JSON.stringify(body);
                }
            }
            const { data } = await api.patch(`${apiUrl}` + path, parsedBody);
            this.fetchError = null;
            return data;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    get = async (path: string, data: any, meta?: true) => {
        try {
            const { data } = await api.get(`${apiUrl}` + path);
            if (data !== undefined) {
                this.fetchError = null;
                return meta ? data : data.data;
            }
            return null;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    login = async (email: string, pwd: string) => {
        return new Promise<boolean>((resolve) => {
            const credentials = { email: email, password: pwd };
            api.post(`${apiUrl}/auth/login`, credentials)
                .then((resAuth) => {
                    this.gettingToken = false;
                    if (resAuth.status === 404) {
                        resolve(false);
                    } else {
                        const token = resAuth.data.data.access_token;
                        const refreshToken = resAuth.data.data.refresh_token;
                        cookie.setCookie('token-mulch', token, 30);
                        cookie.setCookie('refresh_token', refreshToken, 30);
                        this.storedJwt = token;
                        resolve(true);
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                    this.gettingToken = false;
                    resolve(false);
                });
        });
    };

    signUp = async (
        role: string,
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        address: string,
        city: string,
        zipcode: string,
        state: string,
        phone: string,
        acceptedLead: boolean
    ) => {
        return new Promise<any>((resolve) => {
            const params = {
                role: role,
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                address: address,
                city: city,
                zipcode: zipcode,
                state: state,
                phone: phone,
                lead_program: acceptedLead,
            };
            api.post(`${apiUrl}/users`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(null);
                });
        });
    };

    validateAccount = async (salt: string) => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/validate-account?salt=${salt}`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateUser = async (
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        address?: string,
        city?: string,
        zipcode?: string,
        state?: string,
        password?: string,
        lead_program?: boolean
    ) => {
        const params = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            address: address ? address : undefined,
            city: city ? city : undefined,
            zipcode: zipcode ? zipcode : undefined,
            state: state ? state : undefined,
            password: password ? password : undefined,
            lead_program: lead_program !== undefined ? lead_program : undefined,
        };

        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/users/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getProfile = async () => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/users/me?fields=*.*`, params)
                .then((resAuth) => {
                    const role = resAuth.data.data.role.name;
                    cookie.setCookie('role', role, 30);
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getDrops = async () => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/items/drop?fields=*.*.*&limit=-1`, params)
                .then((drops) => {
                    resolve(drops);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getRequests = async (id: string) => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };

        return new Promise<any>((resolve) => {
            api.get(
                `${apiUrl}/items/lead?fields=*.*.*&limit=-1&filter[arborist.id][_eq]=${id}&filter[finished][_eq]=0`,
                params
            )
                .then((drops) => {
                    resolve(drops);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getDrop = async (id: number) => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/items/drop/${id}?fields=*.*.*.*`, params)
                .then((drop) => {
                    resolve(drop);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getImageBaseURL = (imageID: string) => {
        if (imageID === undefined) return '';
        var fullURL = apiUrl + '/assets/' + imageID;
        return fullURL;
    };

    createDrop = async (
        description: string,
        address: string,
        zipcode: string,
        city: string,
        state: string,
        type: number,
        size: number,
        latitude: number,
        longitude: number,
        photo?: File
    ) => {
        var file: any = null;

        if (photo !== undefined && photo !== null) {
            file = await this.uploadFile(photo)
                .then((res) => {
                    console.log(res);
                    return res;
                })
                .catch((err) => err);
        }

        const params = {
            description: description,
            address: address,
            zipcode: zipcode,
            city: city,
            state: state,
            drop_type: type,
            drop_size: size,
            latitude: latitude,
            longitude: longitude,
            priority: 1,
            payment_status: 3,
            drop_status: 1,
            photo: file !== undefined && file !== null ? file.data.data.id : null,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/items/drop`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateDrop = async (
        id: number,
        description: string,
        address: string,
        zipcode: string,
        city: string,
        state: string,
        type: number,
        size: number,
        latitude: number,
        longitude: number
    ) => {
        const params = {
            description: description,
            address: address,
            zipcode: zipcode,
            city: city,
            state: state,
            drop_type: type,
            drop_size: size,
            latitude: latitude,
            longitude: longitude,
            priority: 1,
            payment_status: 2,
            drop_status: 1,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/drop/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    assignDrop = async (dropId: number, arborist: string, arboristName: string) => {
        const params = {
            arborist: arborist,
            drop_status: 2,
            arborist_name: arboristName,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/drop/${dropId}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    completeDrop = async (id: number) => {
        const params = {
            drop_status: 3,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/drop/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    cancelDrop = async (id: number) => {
        const params = {
            drop_status: 4,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/drop/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    unassignDrop = async (userId: string, drop: interfaceDrop, reason: string) => {
        const cancellationList = [{ drop_id: drop.id }];
        return new Promise<any>((resolve) => {
            this.createCancellation(cancellationList, userId, reason)
                .then((resCancellation) => {
                    if (resCancellation.status === 200) {
                        const params = {
                            arborist: null,
                            drop_status: 1,
                        };
                        api.patch(`${apiUrl}/items/drop/${drop.id}`, params)
                            .then((res) => {
                                resolve(res);
                            })
                            .catch((err) => {
                                resolve(404);
                            });
                    } else {
                        resolve(404);
                    }
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    createServiceRequest = async (params: any) => {
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/items/lead`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateDropService = async (id: number, service_id: number) => {
        const params = {
            lead: service_id,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/drop/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    serviceDecline = async (id: number) => {
        const params = {
            arborist: null,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/lead/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    serviceCompleted = async (id: number, answer: string) => {
        const params = {
            finished_condition: answer,
            finished: true,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/lead/${id}`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    createCancellation = async (cancellationList: any, userId: string, cancellation: string) => {
        const params = {
            drop: cancellationList,
            arborist: userId,
            reason: cancellation,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/items/cancellations_list`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    checkout = async (idDrop: number, amount: number) => {
        const params = {
            idDrop: idDrop,
            amount: amount,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/checkout`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    validateRecaptcha = async (token: string) => {
        const params = {
            token: token,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/validate-recaptcha`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    forgotPassword = async (email: string) => {
        const params = {
            email: email,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/forgot-password/forgot`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    createNewPassword = async (token: string, email: string, password: string) => {
        const params = {
            email: email,
            password: password,
            token: token,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/forgot-password/reset`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    uploadFile = async (file: any) => {
        var bodyFormData = new FormData();
        bodyFormData.append('name', file.name);
        bodyFormData.append('file', file);

        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/files`, bodyFormData)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };
}
