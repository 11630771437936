import { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import {
    getTermsPage,
    getTermsUsePage,
    getPrivacyCAPage,
    getPrivacyPage,
    getCookiePage,
    privacy,
    privacy_ca,
    cookie,
    terms,
    termsUse,
} from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
import useGaTracker from '../../navigation/useGaTracker';

const APIManager = API.instance;

interface Props {}

const TextPage: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const TERMS_PAGE = useSelector(terms);
    const TERMSUSE_PAGE = useSelector(termsUse);
    const COOKIE_PAGE = useSelector(cookie);
    const PRIVACY_PAGE = useSelector(privacy);
    const PRIVACY_CA_PAGE = useSelector(privacy_ca);
    const [PAGE, setPage] = useState<any | null>([]);
    const [loadedPath, setLoadedPath] = useState('');

    useEffect(() => {
        if (loader) {
            const path = window.location.pathname.replaceAll('/mulch2garden/', '');
            if (path.includes('privacy-policy-ca')) {
                setPage(PRIVACY_CA_PAGE);
            } else if (path.includes('privacy-policy')) {
                setPage(PRIVACY_PAGE);
            } else if (path.includes('cookie-policy')) {
                setPage(COOKIE_PAGE);
            } else if (path.includes('terms-of-service')) {
                setPage(TERMS_PAGE);
            } else if (path.includes('terms-use')) {
                setPage(TERMSUSE_PAGE);
            }
            setLoader(false);
        }
    }, [TERMS_PAGE, COOKIE_PAGE, PRIVACY_PAGE, PRIVACY_CA_PAGE, TERMSUSE_PAGE]);

    useEffect(() => {
        setLoader(true);
        const path = window.location.pathname.replaceAll('/mulch2garden/', '');
        if (path.includes('privacy-policy-ca')) {
            dispatch(getPrivacyCAPage());
        } else if (path.includes('privacy-policy')) {
            dispatch(getPrivacyPage());
        } else if (path.includes('cookie-policy')) {
            dispatch(getCookiePage());
        } else if (path.includes('terms-of-service')) {
            dispatch(getTermsPage());
        } else if (path.includes('terms-use')) {
            dispatch(getTermsUsePage());
        }
    }, []);

    const checkPath = () => {
        const path = window.location.pathname.replaceAll('/mulch2garden/', '');
        if (path == loadedPath) {
            return;
        } else {
            setLoadedPath(path);
            setLoader(true);
        }
        if (path.includes('privacy-policy-ca')) {
            dispatch(getPrivacyCAPage());
        } else if (path.includes('privacy-policy')) {
            dispatch(getPrivacyPage());
        } else if (path.includes('cookie-policy')) {
            dispatch(getCookiePage());
        } else if (path.includes('terms-of-service')) {
            dispatch(getTermsPage());
        } else if (path.includes('terms-use')) {
            dispatch(getTermsUsePage());
        }
    };

    useGaTracker();

    return PAGE !== null ? (
        <Wrapper seo_title={(PAGE as any).page_title} seo_description={(PAGE as any).seo_description}>
            {checkPath()}
            {loader ? (
                <div className="emptystate" />
            ) : (
                <div>
                    <section className="page">
                        <div className="container mb-5">
                            <div className="row d-flex justify-content-center">
                                <div className="col-12 col-lg-9">
                                    <h2 className="mb-4">{(PAGE as any).page_title}</h2>
                                    <span className="page-text bold-alt">
                                        {(PAGE as any).page_text && htmr((PAGE as any).page_text)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </Wrapper>
    ) : null;
};

export default TextPage;
