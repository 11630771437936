import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from "../../../helpers/i18n";
import { useHistory, useLocation } from "react-router-dom";
interface Props {
    data: any
}

const Supporter : FC <Props> = (props) => {

    const handleClick = (event: any) => {
        window.open(props.data.url);
    }

    return (
       <div className="supporter-block">
           <button onClick={handleClick} className="button">
                <img className="image" src={'https://mulchcms.tecdam.io/assets/'+props.data.logo}/>
                <span className="name">
                    {props.data.name}
                </span>
           </button>
       </div>
    );
}

export default Supporter;