import React, { FC, useEffect, useState } from 'react';
import I18n from '../../../../helpers/i18n';
import API from '../../../../helpers/api';
import InputField from '../../../../components/elements/inputfield/inputfield';
import { initialUser, interfaceUser } from '../../../../models/user';
import useDebounce from '../../../../hooks/use-debounce';
import Helpers from '../../../../helpers/functions';
import { statesUSA } from '../../../../helpers/statesUSA';
import Select from '../../../../components/elements/select/select';
import Button from '../../../../components/elements/button/button';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../redux/auth';
import Checkbox from '../../../../components/elements/checkbox/checkbox';
const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {
    user: interfaceUser;
}

const AccountDetails: FC<Props> = (props) => {
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();
    const [loaderForm, setLoaderForm] = useState(false);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);

    const [firstName, setFirstName] = useState(props.user.first_name);
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');

    const [lastName, setLastName] = useState(props.user.last_name);
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');

    const [email, setEmail] = useState(props.user.email);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [address, setAddress] = useState(props.user.address);
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [city, setCity] = useState(props.user.city);
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [zipcode, setZipcode] = useState(props.user.zipcode);
    const [zipcodeError, setZipcodeError] = useState(false);
    const [zipcodeErrorText, setZipcodeErrorText] = useState('');

    const [state, setState] = useState(props.user.state);
    const [stateError, setStateError] = useState(false);
    const [stateErrorText, setStateErrorText] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorText, setNewPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    const [acceptedLead, setAcceptedLead] = useState(props.user.lead_program);

    const debouncedFirstNameTerm = useDebounce(firstName, 400);
    useEffect(() => {
        if (firstName !== '') {
            let valField = Validators.validateString(firstName);
            setFirstNameError(valField[0]);
            setFirstNameErrorText(valField[1]);
        }
    }, [debouncedFirstNameTerm]);

    const debouncedLastNameTerm = useDebounce(lastName, 400);
    useEffect(() => {
        if (lastName !== '') {
            let valField = Validators.validateString(lastName);
            setLastNameError(valField[0]);
            setLastNameErrorText(valField[1]);
        }
    }, [debouncedLastNameTerm]);

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    const debouncedAddressTerm = useDebounce(address, 400);
    useEffect(() => {
        if (address !== '') {
            let valField = Validators.validateString(address);
            setAddressError(valField[0]);
            setAddressErrorText(valField[1]);
        }
    }, [debouncedAddressTerm]);

    const debouncedCityTerm = useDebounce(city, 400);
    useEffect(() => {
        if (city !== '') {
            let valField = Validators.validateString(city);
            setCityError(valField[0]);
            setCityErrorText(valField[1]);
        }
    }, [debouncedCityTerm]);

    const debouncedZipcodeTerm = useDebounce(zipcode, 400);
    useEffect(() => {
        if (zipcode !== '') {
            let valField = Validators.validateZipCode(zipcode);
            setZipcodeError(valField[0]);
            setZipcodeErrorText(valField[1]);
        }
    }, [debouncedZipcodeTerm]);

    const debouncedStateTerm = useDebounce(state, 400);
    useEffect(() => {
        if (state !== '') {
            let valField = Validators.validateString(state);
            setStateError(valField[0]);
            setStateErrorText(valField[1]);
        }
    }, [debouncedStateTerm]);

    const debouncedNewPasswordTerm = useDebounce(newPassword, 400);
    useEffect(() => {
        if (newPassword !== '') {
            let valField = Validators.validateCreateNewPassword(newPassword);
            setNewPasswordError(valField[0]);
            setNewPasswordErrorText(valField[1]);
        }
    }, [debouncedNewPasswordTerm]);

    const debouncedConfirmPasswordTerm = useDebounce(confirmPassword, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            let valField = Validators.validateConfirmNewPassword(newPassword, confirmPassword);
            setConfirmPasswordError(valField[0]);
            setConfirmPasswordErrorText(valField[1]);
        }
    }, [debouncedConfirmPasswordTerm]);

    const onSubmit = () => {
        if (
            firstNameError ||
            lastNameError ||
            emailError ||
            addressError ||
            cityError ||
            zipcodeError ||
            stateError ||
            newPasswordError ||
            confirmPasswordError
        ) {
            return;
        }
        setLoaderForm(true);
        setErrorUpdateForm(false);

        if (props.user.role.name === 'Gardener') {
            APIManager.updateUser(
                props.user.id,
                firstName,
                lastName,
                email,
                address === null ? undefined : address,
                city === null ? undefined : city,
                zipcode === null ? undefined : zipcode,
                state === null ? undefined : state,
                newPassword === '' || newPassword !== confirmPassword ? undefined : newPassword
            )
                .then((res) => {
                    setLoaderForm(false);
                    if (res.status !== 200) {
                        setErrorUpdateForm(true);
                        return;
                    }
                    dispatch(
                        setUser({
                            ...props.user,
                            first_name: res.data.data.first_name,
                            last_name: res.data.data.last_name,
                            email: res.data.data.email,
                            city: res.data.data.city,
                            address: res.data.data.address,
                            zipcode: res.data.data.zipcode,
                            state: res.data.data.state,
                            lead_program: res.data.data.lead_program,
                        })
                    );
                })
                .catch((err) => {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                    console.log(err);
                });
        } else {
            APIManager.updateUser(
                props.user.id,
                firstName,
                lastName,
                email,
                address === null ? undefined : address,
                city === null ? undefined : city,
                zipcode === null ? undefined : zipcode,
                state === null ? undefined : state,
                newPassword === '' || newPassword !== confirmPassword ? undefined : newPassword,
                acceptedLead === null ? undefined : acceptedLead
            )
                .then((res) => {
                    setLoaderForm(false);
                    if (res.status !== 200) {
                        setErrorUpdateForm(true);
                        return;
                    }
                    dispatch(
                        setUser({
                            ...props.user,
                            first_name: res.data.data.first_name,
                            last_name: res.data.data.last_name,
                            email: res.data.data.email,
                            city: res.data.data.city,
                            address: res.data.data.address,
                            zipcode: res.data.data.zipcode,
                            state: res.data.data.state,
                            lead_program: res.data.data.lead_program,
                        })
                    );
                })
                .catch((err) => {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                    console.log(err);
                });
        }
    };

    const deleteAccount = () => {
        var body =
            'I want to delete my account (on the name of ' +
            firstName +
            ' ' +
            lastName +
            ') that is linked to the following e-mail address: ' +
            email +
            '.';

        window.open('mailto:contact@mulch2garden.com?subject=Delete Account&body=' + body);
    };

    return (
        <section className="account-details">
            <form onSubmit={onSubmit}>
                <h3 className="mb-3">{I18n.t('FORM_DETAILS')}</h3>
                <InputField value={props.user.role.name} label={I18n.t('FORM_ROLE')} disabled />
                <InputField
                    value={firstName}
                    label={I18n.t('FORM_FIRSTNAME')}
                    onChange={(val) => setFirstName(val.target.value)}
                    error={firstNameError}
                    errorText={firstNameErrorText}
                />
                <InputField
                    value={lastName}
                    label={I18n.t('FORM_LASTNAME')}
                    onChange={(val) => setLastName(val.target.value)}
                    error={lastNameError}
                    errorText={lastNameErrorText}
                />
                <InputField
                    value={email}
                    label={I18n.t('FORM_EMAIL')}
                    email
                    onChange={(val) => setEmail(val.target.value)}
                    error={emailError}
                    errorText={emailErrorText}
                />
                <InputField
                    value={address}
                    label={I18n.t('FORM_ADDRESS')}
                    onChange={(val) => setAddress(val.target.value)}
                    error={addressError}
                    errorText={addressErrorText}
                />
                <InputField
                    value={city}
                    label={I18n.t('FORM_CITY')}
                    onChange={(val) => setCity(val.target.value)}
                    error={cityError}
                    errorText={cityErrorText}
                />
                <InputField
                    value={zipcode}
                    label={I18n.t('FORM_ZIPCODE')}
                    onChange={(val) => setZipcode(val.target.value)}
                    error={zipcodeError}
                    errorText={zipcodeErrorText}
                />
                <Select
                    value={state}
                    array={statesUSA}
                    onChange={(val: any) => setState(val.target.value)}
                    label={I18n.t('FORM_STATE')}
                    error={stateError}
                    errorText={stateErrorText}
                />
                {props.user.role.name === 'Arborist' && (
                    <Checkbox
                        onChange={(e: any) => setAcceptedLead(e)}
                        checked={acceptedLead}
                        customLabel
                        label={
                            <div className="custom-content">
                                <span>{I18n.t('SIGNUP_TERMS_LEAD')}</span>
                            </div>
                        }
                        name={'terms_lead'}
                    />
                )}
                <h3 className="mt-5 mb-3">{I18n.t('FORM_PASSWORD_CHANGE')}</h3>
                <InputField
                    value={newPassword}
                    label={I18n.t('FORM_NEW_PASSWORD')}
                    secure
                    onChange={(val) => setNewPassword(val.target.value)}
                    error={newPasswordError}
                    errorText={newPasswordErrorText}
                />
                <InputField
                    value={confirmPassword}
                    label={I18n.t('FORM_CONFIRM_PASSWORD')}
                    secure
                    onChange={(val) => setConfirmPassword(val.target.value)}
                    error={confirmPasswordError}
                    errorText={confirmPasswordErrorText}
                />
                <div className="mt-5" />
                <Button
                    fullWidth
                    small
                    title={loaderForm ? I18n.t('LOGIN_WAIT') : I18n.t('FORM_SAVE')}
                    onClick={onSubmit}
                />
                {errorUpdateForm && <div className="error-send-form">{I18n.t('FORM_SEND_ERROR')}</div>}
            </form>
            <div className="d-block text-align-center warning-text" onClick={() => deleteAccount()}>
                {I18n.t('DELETE_ACCOUNT')}
            </div>
        </section>
    );
};

export default AccountDetails;
