import React, { FC, useState } from 'react';

interface Props {
    onChange?: (event: any) => void,
    onBlur?: (event: any) => void,
    value: string,
    secure?: boolean,
    email?: boolean,
    error?: boolean,
    errorText?: string,
    label: string,
    maxLength?: number,
    disabled?: boolean,
    numeric?: any,
    pattern?: any,
    autocomplete?: any,
    successIconDelayed?: any,
    validField?: boolean,
    textarea?: boolean
}
const InputField : FC <Props> = (props) => {
    const [changeTriggered, setChangeTriggered] = useState(false);

    const onChange = (event: any) => {
        setChangeTriggered(true);
        if(props.onChange){
            props.onChange(event);
        }
    }
      return (
        <div>
            <div className={props.error ? "input-wrapper error" : "input-wrapper"}>
                {
                    props.textarea ?
                    <textarea 
                        rows={3}
                        value={props.value}
                        className={props.disabled ? "input disabled" : "input"}
                        placeholder=" "
                        onBlur={props.onBlur}
                        onChange={onChange}
                        maxLength={props.maxLength ? props.maxLength : 500}
                        disabled={props.disabled}
                        autoComplete={props.autocomplete ? props.autocomplete : ''} />
                    :
                    <input 
                        type={props.numeric ? "number" : props.secure ? "password" : props.email ? "email" : "text"}
                        value={props.value}
                        className={props.disabled ? "input disabled" : "input"}
                        placeholder=" "
                        onBlur={props.onBlur}
                        onChange={onChange}
                        maxLength={props.maxLength ? props.maxLength : 500}
                        disabled={props.disabled}
                        autoComplete={props.autocomplete ? props.autocomplete : ''}
                    />
                }
                
                <label>{props.label}</label>
            </div>
            {props.error &&
                <span className="error-text">{props.errorText}</span>
            }
            
        </div>
      );
}
export default InputField;