import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from "../../../helpers/i18n";
import Button from '../../elements/button/button';
import { Link } from 'react-router-dom';
interface Props {
    title: string,
    text: string,
    buttonUrl: string,
    buttonUrlText: string
}

const TextButtonContainer : FC <Props> = (props) => {
    return (
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-7 text-align-lg-center">
                    <div className="d-flex flex-column align-items-lg-center mb-3">
                        <h2>
                            {props.title}
                        </h2>
                        <span className="line"/>
                    </div>
                    <span className="page-text mb-4">
                        {props.text}
                    </span>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-3 mt-2">
                    <Button
                        title={props.buttonUrlText}
                        navTo={props.buttonUrl}
                        clear alt
                    />
                </div>
            </div>
        </div>
    );
}

export default TextButtonContainer;