import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../../../helpers/api';
import I18n from '../../../../helpers/i18n';
import { interfaceDrop } from '../../../../models/drop';
import { user } from '../../../../redux/auth';
import { drops } from '../../../../redux/drops';
import DropCardGardener from '../dropCard/dropCardGardener';
import sort_by from '../../../../images/sort_by.svg';
import { useHistory } from 'react-router-dom';

const APIManager = API.instance;
interface Props {
    getDrops: any;
}

const DropsRequest: FC<Props> = (props) => {
    const history = useHistory();
    const currentDrops = useSelector(drops);
    const currentUser = useSelector(user);
    const [sort, setSort] = useState(true);

    const onDonation = (id: number) => {
        APIManager.checkout(id, 49)
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = res.data.url;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onCancelByGardener = (id: number) => {
        APIManager.cancelDrop(id)
            .then((res) => {
                if (res.status === 200) {
                    props.getDrops();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const conditionRequest = (drop: interfaceDrop) => {
        return currentUser.id === drop.user_created.id && [1, 2].includes(drop.drop_status.id);
    };

    const payDrop = (drop: any) => {
        APIManager.checkout(drop.id, drop.priority.id === 1 ? 19.95 : 49)
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = res.data.url;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <section className="drops-request">
            <div className="d-flex justify-content-between">
                <h3>{I18n.t('MENU_DROPS_REQUEST')}</h3>
                <img src={sort_by} alt="sort drops" onClick={() => setSort(!sort)} className="img-sort" />
            </div>
            {[...currentDrops.drops]
                .sort((a, b) =>
                    a.date_updated > b.date_updated
                        ? sort
                            ? -1
                            : 1
                        : b.date_updated > a.date_updated
                        ? sort
                            ? 1
                            : -1
                        : 0
                )
                .map((drop: interfaceDrop) => {
                    return (
                        <DropCardGardener
                            drop={drop}
                            key={drop.id}
                            onDonation={onDonation}
                            onCancelByGardener={onCancelByGardener}
                            onEditDrop={() => history.push(`/account/update-drop/${drop.id}`)}
                            completePayment={payDrop}
                            onRequestService={() => history.push(`/account/request-service/${drop.id}`)}
                        />
                    );
                })}
        </section>
    );
};

export default DropsRequest;
