import React, { FC } from 'react';
import I18n from "../../../../helpers/i18n";
import NewDrop from './components/newDrop/newDrop';

interface Props {
}

const CreateDrop: FC<Props> = (props) => {
    return (
        <section className='drops'>
            <h3>{I18n.t('DROP_NEW')}</h3>
            <NewDrop />
        </section>
    );
}

export default CreateDrop;
