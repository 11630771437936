import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../../../../helpers/api';
import I18n from "../../../../../helpers/i18n";
import Helpers from '../../../../../helpers/functions';
import useDebounce from '../../../../../hooks/use-debounce';
import InputField from '../../../../../components/elements/inputfield/inputfield';
import { dropTypes } from '../../../../../helpers/dropTypes';
import { dropSizes } from '../../../../../helpers/dropSizes';
import Button from '../../../../../components/elements/button/button';
import Geocode from "react-geocode";
import { user } from '../../../../../redux/auth';
import { useHistory } from 'react-router-dom';
import Checkbox from '../../../../../components/elements/checkbox/checkbox';

const APIManager = API.instance;
const Validators = Helpers.instance;


interface Props {
}

const NewDrop: FC<Props> = (props) => {
    const history = useHistory();
    const currentUser = useSelector(user);
    const [loaderForm, setLoaderForm] = useState(false);
    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);

    const [trees, setTrees] = useState('');
    const [typeTrees, setTypeTrees] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [description, setDescription] = useState('');
    const [treesError, setTreesError] = useState(false);
    const [treesErrorText, setTreesErrorText] = useState('');
    const [typeTreesError, setTypeTreesError] = useState(false);
    const [typeTreesErrorText, setTypeTreesErrorText] = useState('');
    const [timeFrameError, setTimeFrameError] = useState(false);
    const [timeFrameErrorText, setTimeFrameErrorText] = useState('');

    const debouncedTrees = useDebounce(trees, 400);
    const debouncedTypeTrees = useDebounce(typeTrees, 400);
    const debouncedTimeFrame = useDebounce(timeFrame, 400);

    const [filter, setFilter] = useState({
        need_trees_pruned: true,
        need_trees_removed: false,
        need_trees_planted: false,
        other_gardening_services: false
    });

    useEffect(() => {
        let valField = Validators.validateString(trees);
        setTreesError(valField[0]);
        setTreesErrorText(valField[1]);
    }, [debouncedTrees]);

    useEffect(() => {
        let valField = Validators.validateString(typeTrees);
        setTypeTreesError(valField[0]);
        setTypeTreesErrorText(valField[1]);
    }, [debouncedTypeTrees]);

    useEffect(() => {
        let valField = Validators.validateString(timeFrame);
        setTimeFrameError(valField[0]);
        setTimeFrameErrorText(valField[1]);
    }, [debouncedTimeFrame]);

    const checkIfRegistrationIsAllowed = () => {
        if( 
            trees !== '' && !treesError && typeTrees !== '' && !typeTreesError && timeFrame !== '' && !timeFrameError
        ){
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    }

    useEffect(() => {
        checkIfRegistrationIsAllowed()
    }, [description,
        trees,
        treesError,
        typeTrees,
        typeTreesError,
        timeFrame,
        timeFrameError
    ]);

    const onSubmit = async (e: any) => {
        e.preventDefault()
        if (disableForm) return;

        var dropId = "0";
        if(window.location.href.split('/').length > 0) {
            var splitted = window.location.href.split('/');
            dropId = splitted[splitted.length-1];
        }        
        

        setLoaderForm(true);
        setErrorUpdateForm(false);

        APIManager.getDrop(parseInt(dropId)).then(res => {
            if(res.status == 200) {
                let arboristId = res.data.data.arborist.id;
                
                // props.drop.arborist?.lead_program == true
                let params = {
                    needed_service: filter,
                    num_trees: parseInt(trees),
                    type_trees: typeTrees,
                    time_frame: timeFrame,
                    description: description,
                    arborist: res.data.data.arborist.lead_program === true ? arboristId : null,
                    drop: parseInt(dropId)
                }

                APIManager.createServiceRequest(params)
                .then(res => {
                    APIManager.updateDropService(
                        parseInt(dropId),
                        res.data.data.id
                    )
                    .then(res => {
                        setLoaderForm(false);
                        if (res.status === 200) {
                            history.push('/account/drops-received');
                        } else {
                            setLoaderForm(false);
                            setErrorUpdateForm(true);
                        }
                    })
                    .catch(err => {
                        setLoaderForm(false);
                        setErrorUpdateForm(true);
                    })
                })
                .catch(err => {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                })
            }
        })
        .catch(error => {  
            console.log(error);
        });
    }
    
    return (
        <form onSubmit={onSubmit}>
            <div className='mt-4'></div>
            <Checkbox 
                onChange={(e: any) => setFilter({
                    ...filter,
                    need_trees_pruned: e
                })}
                checked={filter.need_trees_pruned}
                label={I18n.t('SERVICE_NEED_TREES_PRUNED')}
                name={I18n.t('SERVICE_NEED_TREES_PRUNED')} />
            <Checkbox 
                onChange={(e: any) => setFilter({
                    ...filter,
                    need_trees_removed: e
                })}
                checked={filter.need_trees_removed}
                label={I18n.t('SERVICE_NEED_TREES_REMOVED')}
                name={I18n.t('SERVICE_NEED_TREES_REMOVED')} />
            <Checkbox 
                onChange={(e: any) => setFilter({
                    ...filter,
                    need_trees_planted: e
                })}
                checked={filter.need_trees_planted}
                label={I18n.t('SERVICE_NEED_TREES_PLANTED')}
                name={I18n.t('SERVICE_NEED_TREES_PLANTED')} />
            <Checkbox 
                onChange={(e: any) => setFilter({
                    ...filter,
                    other_gardening_services: e
                })}
                checked={filter.other_gardening_services}
                label={I18n.t('SERVICE_OTHER_SERVICES')}
                name={I18n.t('SERVICE_OTHER_SERVICES')} />
            <InputField 
                value={trees} 
                label={I18n.t('SERVICE_NUMBER_TREES')}
                onChange={(val) => setTrees(val.target.value)}
                error={treesError}
                errorText={treesErrorText}
                textarea />
            <InputField 
                value={typeTrees} 
                label={I18n.t('SERVICE_TYPETREES')}
                onChange={(val) => setTypeTrees(val.target.value)}
                error={typeTreesError}
                errorText={typeTreesErrorText}
                textarea />
            <InputField 
                value={timeFrame} 
                label={I18n.t('SERVICE_TIMEFRAME')}
                onChange={(val) => setTimeFrame(val.target.value)}
                error={timeFrameError}
                errorText={timeFrameErrorText}
                textarea />
            <InputField 
                value={description} 
                label={I18n.t('DROP_DESCRIPTION')}
                onChange={(val) => setDescription(val.target.value)}
                error={false}
                textarea />
            <span className="instructions-drop">{I18n.t('SERVICE_HINT')}</span>
            <Button
                title={I18n.t('SERVICE_REQUEST')}
                fullWidth
                loader={loaderForm}
                disabled={disableForm} />
            {
                errorUpdateForm &&
                <span className="instructions-drop error-update">{I18n.t('FORM_SEND_ERROR')}</span>
            }
        </form>
    );
}

export default NewDrop;
