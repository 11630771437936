import React, { FC, useState } from 'react';
import Button from '../../../../components/elements/button/button';
import InputField from '../../../../components/elements/inputfield/inputfield';
import API from '../../../../helpers/api';
import I18n from '../../../../helpers/i18n';
import { interfaceDrop } from '../../../../models/drop';
import MapDrop from '../mapDrop/mapDrop';

const apiUrl = 'https://mulchcms.tecdam.io';

const APIManager = API.instance;
interface Props {
    drop: interfaceDrop;
    onSuccessful?: any;
    onCancelByArborist?: any;
    idDropUpdating?: number;
    loaderSuccess?: boolean;
    loaderCancel?: boolean;
}

const DropCardArborist: FC<Props> = (props) => {
    const [textCopied, setTextCopied] = useState(false);
    const [showCancelInput, setShowCancelInput] = useState(false);
    const [cancelInput, setCancelInput] = useState('');

    const formatDate = (date: string) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const copyContent = (drop: interfaceDrop) => {
        let text = `----- ${I18n.t('DROP_ARBORIST')} -----\n`;
        text += `${I18n.t('DROP_NAME')}: ${drop.user_created.first_name} ${drop.user_created.last_name}\n`;
        text += `${I18n.t('DROP_PHONE')}: ${drop.user_created.phone}\n`;
        text += `${I18n.t('DROP_EMAIL')}: ${drop.user_created.email}\n`;
        text += `\n`;
        text += `----- ${I18n.t('DROP_INFO')} -----\n`;
        text += `${I18n.t('DROP_ORDER_NO')}: ${drop.id}\n`;
        text += `${I18n.t('DROP_STATUS')}: ${drop.drop_status.value}\n`;
        text += `${I18n.t('DROP_DATE')}: ${formatDate(drop.date_created)}\n`;
        text += `${I18n.t('DROP_ADDRESS')}: ${drop.address}\n`;
        text += `${I18n.t('DROP_CITY')}: ${props.drop.zipcode} ${props.drop.city}\n`;
        text += `${I18n.t('DROP_TYPE')}: ${drop.drop_type.type}\n`;
        text += `${I18n.t('DROP_SIZE')}: ${drop.drop_size.size}\n`;
        text += `${I18n.t('DROP_DESCRIPTION')}: ${drop.description}\n`;

        navigator.clipboard.writeText(text);
        setTextCopied(true);
    };

    return (
        <div className="drop-card">
            <div className="header-card">
                <div>
                    <span>{I18n.t('DROP_ORDER_NO')}: </span>
                    {props.drop.id}
                </div>
            </div>
            <div className="body-card">
                <div className="user-info">
                    <h4>{I18n.t('DROP_GARDENER')}</h4>
                    <div>
                        <span>{I18n.t('DROP_NAME')}: </span>
                        {props.drop.user_created.first_name} {props.drop.user_created.last_name}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_PHONE')}: </span>
                        <a href={`tel:${props.drop.user_created.phone}`}>{props.drop.user_created.phone}</a>
                    </div>
                    <div>
                        <span>{I18n.t('DROP_EMAIL')}: </span>
                        <a href={`mailto:${props.drop.user_created.email}`}>{props.drop.user_created.email}</a>
                    </div>
                </div>
                <div className="drop-info">
                    <h4>{I18n.t('DROP_INFO')}</h4>
                    <div>
                        <span>{I18n.t('DROP_DATE')}: </span>
                        {formatDate(props.drop.date_created)}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_ADDRESS')}: </span>
                        {props.drop.address}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_CITY')}: </span>
                        {props.drop.zipcode} {props.drop.city}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_TYPE')}: </span>
                        {props.drop.drop_type.type}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_SIZE')}: </span>
                        {props.drop.drop_size.size}
                    </div>
                    <div></div>
                    <div>
                        <span>{I18n.t('DROP_DESCRIPTION')}: </span>
                        {props.drop.description}
                    </div>
                    {props.drop.photo !== null && <span>{I18n.t('DROP_IMAGE_GARDENER')}: </span>}
                    {props.drop.photo !== null && (
                        <div>
                            <a href={apiUrl + '/assets/' + props.drop.photo.filename_disk} target="_blank">
                                <img className="dropImg" src={apiUrl + '/assets/' + props.drop.photo.filename_disk} />
                            </a>
                        </div>
                    )}
                    {props.drop.drop_status.id === 2 && (
                        <MapDrop lat={props.drop.latitude} lng={props.drop.longitude} />
                    )}
                </div>
                <div className="button-copy" onClick={() => copyContent(props.drop)}>
                    {textCopied ? I18n.t('DROP_SUCCESSFUL_COPIED') : I18n.t('DROP_COPY_DROP')}
                </div>
                {props.drop.drop_status.id === 2 && (
                    <div className="buttons-card">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <Button
                                    fullWidth
                                    small
                                    title={I18n.t('DROP_SUCCESSFUL')}
                                    onClick={() => props.onSuccessful(props.drop.id)}
                                    loader={props.drop.id === props.idDropUpdating && props.loaderSuccess}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Button
                                    disabled={showCancelInput}
                                    fullWidth
                                    small
                                    title={I18n.t('DROP_CANCEL_BY_ARBORIST')}
                                    onClick={() => setShowCancelInput(true)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {showCancelInput && (
                    <>
                        <InputField
                            textarea
                            value={cancelInput}
                            label={I18n.t('FORM_CANCEL_DROP')}
                            onChange={(val) => setCancelInput(val.target.value)}
                        />
                        <Button
                            disabled={cancelInput === ''}
                            fullWidth
                            small
                            title={I18n.t('FORM_SEND_CANCELLATION')}
                            onClick={() => props.onCancelByArborist(props.drop, cancelInput)}
                            loader={props.drop.id === props.idDropUpdating && props.loaderCancel}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default DropCardArborist;
