import React, { FC, useState } from 'react';

interface Props {
    onChange: any,
    checked: boolean,
    label: any,
    name: string,
    customLabel?: boolean
}
const Checkbox : FC <Props> = (props) => {
    return (
        <label 
            htmlFor={props.name}
            className={`custom-checkbox ${props.customLabel ? "custom-label" : "default-label"}`}
            key={props.name}>
            <input 
                type="checkbox" 
                name={props.name}
                checked={props.checked}
                onChange={(e) => props.onChange(e.target.checked)} />
            <span className="checkmark"></span>
            {props.label}
        </label>
    );
}
export default Checkbox;