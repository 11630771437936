import React, { FC, useEffect, useState } from 'react';
import Button from '../../../../components/elements/button/button';
import API from '../../../../helpers/api';
import I18n from '../../../../helpers/i18n';
import { interfaceDrop } from '../../../../models/drop';

const APIManager = API.instance;
interface Props {
    drop: interfaceDrop;
    onDonation?: any;
    onCancelByGardener?: any;
    onEditDrop?: any;
    completePayment?: any;
    onRequestService?: any;
}

const DropCardGardener: FC<Props> = (props) => {
    const [textCopied, setTextCopied] = useState(false);

    const formatDate = (date: string) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const copyContent = (drop: interfaceDrop) => {
        let text = `----- ${I18n.t('DROP_ARBORIST')} -----\n`;
        text += `${I18n.t('DROP_NAME')}: ${drop.arborist?.first_name} ${drop.arborist?.last_name}\n`;
        text += `${I18n.t('DROP_PHONE')}: ${drop.arborist?.phone}\n`;
        text += `${I18n.t('DROP_EMAIL')}: ${drop.arborist?.email}\n`;
        text += `\n`;
        text += `----- ${I18n.t('DROP_INFO')} -----\n`;
        text += `${I18n.t('DROP_ORDER_NO')}: ${drop.id}\n`;
        text += `${I18n.t('DROP_STATUS')}: ${drop.drop_status.value} ${drop.payment_status.value}\n`;
        text += `${I18n.t('DROP_DATE')}: ${formatDate(drop.date_created)}\n`;
        text += `${I18n.t('DROP_ADDRESS')}: ${drop.address}\n`;
        text += `${I18n.t('DROP_CITY')}: ${props.drop.zipcode} ${props.drop.city}\n`;
        text += `${I18n.t('DROP_TYPE')}: ${drop.drop_type.type}\n`;
        text += `${I18n.t('DROP_SIZE')}: ${drop.drop_size.size}\n`;
        text += `${I18n.t('DROP_DESCRIPTION')}: ${drop.description}\n`;

        navigator.clipboard.writeText(text);
        setTextCopied(true);
    };

    return (
        <div className="drop-card">
            <div className="header-card">
                <div>
                    <span>{I18n.t('DROP_ORDER_NO')}: </span>
                    {props.drop.id}
                </div>
                <div className="drop-status">
                    <span>{I18n.t('DROP_STATUS')}: </span>
                    {props.drop.drop_status.value}
                </div>
            </div>
            <div className="body-card">
                {props.drop.arborist && (
                    <div className="user-info">
                        <h4>{I18n.t('DROP_ARBORIST')}</h4>
                        <div>
                            <span>{I18n.t('DROP_NAME')}: </span>
                            {props.drop.arborist.first_name} {props.drop.arborist.last_name}
                        </div>
                        <div>
                            <span>{I18n.t('DROP_PHONE')}: </span>
                            <a href={`tel:${props.drop.arborist.phone}`}>{props.drop.arborist.phone}</a>
                        </div>
                        <div>
                            <span>{I18n.t('DROP_EMAIL')}: </span>
                            <a href={`mailto:${props.drop.arborist.email}`}>{props.drop.arborist.email}</a>
                        </div>
                    </div>
                )}
                <div className="drop-info">
                    <h4>{I18n.t('DROP_INFO')}</h4>
                    <div>
                        <span>{I18n.t('DROP_DATE')}: </span>
                        {formatDate(props.drop.date_created)}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_UPDATED')}: </span>
                        {formatDate(props.drop.date_updated)}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_ADDRESS')}: </span>
                        {props.drop.address}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_CITY')}: </span>
                        {props.drop.zipcode} {props.drop.city}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_TYPE')}: </span>
                        {props.drop.drop_type.type}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_SIZE')}: </span>
                        {props.drop.drop_size.size}
                    </div>
                    <div>
                        <span>{I18n.t('DROP_DESCRIPTION')}: </span>
                        {props.drop.description}
                    </div>
                    <span>{I18n.t('DROP_CANCELLATIONS')}: </span>
                    <div>
                        {props.drop.cancellations_list.map((x: any) => {
                            if (
                                x.cancellations_list_id !== null &&
                                x.cancellations_list_id.reason !== undefined &&
                                x.cancellations_list_id.reason !== null
                            ) {
                                return <div>{x.cancellations_list_id.reason}</div>;
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropCardGardener;
