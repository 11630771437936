import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import Placeholder from '../../images/placeholder.jpeg';
import PageBanner from '../../components/blocks/page-banner/page-banner';
import VideoContainer from '../../components/blocks/video-container/video-container';
import FaqQuestion from '../../components/elements/faq-question/faq-question';
import { Link, useHistory } from 'react-router-dom';
import { getErrorPaymentPage, error_payment } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
const APIManager = API.instance;
interface Props {
}

const AboutPage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(error_payment);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getErrorPaymentPage());
    }, []);

    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? <div className="emptystate"/> : <div>
                <section className="page">
                    <div className="container mb-5">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-7 text-align-center">
                                <h2 className="mb-4">
                                    {PAGE.page_title}
                                </h2>
                                <span className="page-text">
                                    {PAGE.page_text && htmr(PAGE.page_text)}
                                </span>
                                <span className="page-text">
                                    Go to <Link to={'account'}>{I18n.t('MENU_ACCOUNT')}</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>}
        </Wrapper>
    );
}

export default AboutPage;