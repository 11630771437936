import React, { createRef, FC, LegacyRef, useEffect, useState } from 'react';
import Geocode from "react-geocode";
import { Wrapper } from '@googlemaps/react-wrapper';
import { GoogleMap, Marker } from '@react-google-maps/api';

const apiKey = "AIzaSyAF3aG9i45KWujY6CSwSiY0Sj3Ex-b60Ts";
Geocode.setApiKey(apiKey);

const containerStyle = {
    width: '100%',
    height: '200px',
    marginTop: '20px'
};

interface Props {
    lat: number,
    lng: number
}

const MapDrop: FC<Props> = (props) => {
    const [map, setMap] = useState(createRef<LegacyRef<GoogleMap>>());

    return (
        <Wrapper 
            apiKey={apiKey}
            // @ts-ignore
            libraries={['places']} >
            <GoogleMap
                ref={map.current}
                mapContainerStyle={containerStyle}
                center={{lat: props.lat, lng: props.lng}}
                zoom={16}
                options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                }}>
                <Marker
                    position={{ 
                        lat: props.lat,
                        lng: props.lng
                    }} />
            </GoogleMap>
        </Wrapper>
    );
}

export default MapDrop;
