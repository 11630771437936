import React, { FC } from 'react';
import I18n from "../../../../helpers/i18n";
import RequestServiceComponent from './components/requestService';

interface Props {
}

const RequestService: FC<Props> = (props) => {
    return (
        <section className='drops'>
            <h3>{I18n.t('SERVICE')}</h3>
            <RequestServiceComponent />
        </section>
    );
}

export default RequestService;
