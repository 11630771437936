import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import API from '../../helpers/api';
import { interfaceDrop } from '../../models/drop';
import { AppThunk, RootState } from '../store';
interface thisState {
    drops: Array<interfaceDrop>;
}

const initialState: thisState = {
    drops: []
};

export const thisSlice = createSlice({
    name: 'drops',
    initialState,
    reducers: {
        setDropsRedux: (state, action: PayloadAction<any>) => {
            state.drops = action.payload;
        },
    },
});
export const { setDropsRedux } = thisSlice.actions;

export const drops = (state: RootState) => state.drops;

export default thisSlice.reducer;
