export interface interfaceUser {
    id: string;
    description: string;
    email: string;
    first_name: string;
    last_name: string;
    location: string;
    role: {
        id: string;
        name: string;
    };
    address: string;
    zipcode: string;
    city: string;
    state: string;
    phone: string;
    verified_address: boolean;
    lead_program: boolean;
}

export const initialUser: interfaceUser = {
    id: '',
    description: '',
    email: '',
    first_name: '',
    last_name: '',
    location: '',
    role: {
        id: '',
        name: '',
    },
    address: '',
    zipcode: '',
    city: '',
    state: '',
    phone: '',
    verified_address: false,
    lead_program: false,
};
