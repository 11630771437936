import React, { useState, FC, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import MapComponent from './components/mapComponent/mapComponent';
import useGaTracker from '../../navigation/useGaTracker';
import I18n from '../../helpers/i18n';
import { useHistory } from 'react-router-dom';
import { getMyAccountPage, my_account } from '../../redux/pages';
import API from '../../helpers/api';
import { doLogout, user, setUser } from '../../redux/auth';
import DropsRequest from './components/dropsRequest/dropsRequest';
import { interfaceDrop } from '../../models/drop';
import { setDropsRedux } from '../../redux/drops';
import Modal from '../../components/modal/modal';

interface Props {}

const APIManager = API.instance;

const MapPage: FC<Props> = (props) => {
    useGaTracker();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();

    const getDrops = () => {
        APIManager.getProfile()
            .then((res) => {
                dispatch(setUser(res.data.data));

                APIManager.getDrops()
                    .then((res: { data: { data: React.SetStateAction<interfaceDrop[]> } }) => {
                        dispatch(setDropsRedux(res.data.data));
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Wrapper>
            <DropsRequest getDrops={getDrops} />
        </Wrapper>
    );
};

export default MapPage;
