import React, { useState, FC, useEffect } from 'react';
import I18n from "../../helpers/i18n";
import { Link } from "react-router-dom";
import FacebookIcon from '../../images/facebook.png';
import CookieConsent from "react-cookie-consent";

interface Props {
}

const Footer : FC <Props> = (props) => {
    return (
        <footer>
            <CookieConsent
                location="bottom"
                buttonText={I18n.t('COOKIE_BAR_BUTTON')}
                cookieName="taxt-consent"
                expires={150}
                debug={false}
            >
                {I18n.t('COOKIE_BAR_TEXT')}
            </CookieConsent>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <ul className="menu">
                            <li>
                                <Link
                                    to={'/faq'}
                                >
                                    {I18n.t('MENU_FAQ')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/about'}
                                >
                                    {I18n.t('MENU_ABOUT')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/'}
                                >
                                    {I18n.t('MENU_LOGIN')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/sign-up'}
                                >
                                    {I18n.t('MENU_SIGNUP')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-7 d-flex justify-content-lg-end mt-3 mt-lg-0">
                        <ul className="menu">
                            <li>
                                <a
                                    href={'mailto:' + I18n.t('CONTACT_EMAIL')}
                                >
                                    {I18n.t('CONTACT_EMAIL')}
                                </a>
                            </li>
                            <li>
                                <Link
                                    to={'/terms-of-service'}
                                >
                                    {I18n.t('MENU_TERMS')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/terms-use'}
                                >
                                    {I18n.t('MENU_TERMSUSE')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'/privacy-policy'}
                                >
                                    {I18n.t('MENU_PRIVACY')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={'https://www.facebook.com/Mulch2Gardens-104814361200056'}
                                    target="_blank">
                                    <img src={FacebookIcon} className="fb-icon" alt="Facebook"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;