import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from "../../../helpers/i18n";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
    title?: string,
    videoURL: string,
    col?: string
}

const VideoRow : FC <Props> = (props) => {

    const getWidth = () => {
        var width;

        if(props.col){
            width = "col-12 col-lg-" + props.col;
        } else {
            width = "col-12";
        }
        return width;
    }
    return (
        <div className="container">
            <div className="row mt-5 d-flex justify-content-center">
                <div className={getWidth()}>
                    {props.title &&
                        <div>
                            <h2>
                                {props.title}
                            </h2>
                            <span className="line mb-5"/>
                        </div>
                    }
                    <div className="video-block">
                        <iframe 
                            width="100%"
                            height="515"
                            src={props.videoURL}
                            title="YouTube video player" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoRow;