import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import { Link, useHistory, useParams } from 'react-router-dom';
import { getFAQPage, faq, validation_account } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
import Button from '../../components/elements/button/button';
import Loader from '../../components/elements/loader/loader';
const APIManager = API.instance;
interface Props {
}

const ValidateAccount : FC <Props> = (props) => {
    const { salt } = useParams<{salt: string}>();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(validation_account);
    const [errorValidating, setErrorValidating] = useState(false);
    const [loaderValidating, setLoaderValidating] = useState(true);
    const [messageLoader, setMessageLoader] = useState(I18n.t('VALIDATE_VALIDATING'));


    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getFAQPage());
        validateAccount();
    }, []);

    const validateAccount = () => {
        APIManager.validateAccount(salt)
                .then((res) => {
                    if (res.data.message === 'User activated') {
                        setLoaderValidating(false)
                        setMessageLoader(I18n.t('VALIDATE_REDIRECT'))
                        setTimeout(() => {
                            history.push("/login");
                        }, 1000)
                    } else if (res.data.message === 'User already activated'){
                        setTimeout(() => {
                            setLoaderValidating(false)
                            setMessageLoader(I18n.t('VALIDATE_REDIRECT'));
                        }, 1000)
                        setTimeout(() => {
                            history.push("/login");
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            setLoaderValidating(false)
                            setErrorValidating(true);
                            setMessageLoader(I18n.t('VALIDATE_INVALID'));
                        }, 1000)
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        setLoaderValidating(false);
                        setErrorValidating(true);
                        setMessageLoader(I18n.t('VALIDATE_INVALID'));
                    }, 1000)
                })
    }

    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            <div className={'container validate-page'}>
                <div className="row">
                    <div className="col-12">
                        <h2>{I18n.t('VALIDATE_TITLE')}</h2>
                        <div className="container-laoder">
                            <h4 className={`${errorValidating && 'error'}`}>{messageLoader}</h4>
                            <div className="container-bottom-validate">
                                <Loader show={loaderValidating} />
                                {
                                    errorValidating && 
                                        <span>
                                            <a href="mailto:contact@mulch2garden.com">Send an email: contact@mulch2garden.com</a>
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default ValidateAccount;