import React, { useState, FC, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory} from "react-router-dom";
import { withRouter } from "react-router";

interface Props {
}

const PageBehaviour : FC <Props> = (props) => {
    let location = useLocation();
    let history = useHistory();

    React.useEffect(() => {
        // if(history.action == 'PUSH' && location.pathname !== '/experiences'){
            window.scrollTo(0, 0);
        // }
    }, [location]);

    return(
        null
    );
}

export default withRouter(PageBehaviour);