import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from '../../helpers/i18n';
import PageBanner from '../../components/blocks/page-banner/page-banner';
import VideoContainer from '../../components/blocks/video-container/video-container';
import FaqQuestion from '../../components/elements/faq-question/faq-question';
import Button from '../../components/elements/button/button';
import { Link } from 'react-router-dom';
import { getFAQPage, faq } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
import { user } from '../../redux/auth';
import { useHistory } from 'react-router-dom';

const APIManager = API.instance;
interface Props {}

const FAQPage: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(faq);
    const currentUser = useSelector(user);
    const history = useHistory();

    useEffect(() => {
        if (loader && PAGE !== '') {
            setLoader(false);

            setTimeout(() => {
                const hash = history.location.hash;
                // Check if there is a hash and if an element with that id exists
                const el = hash && document.getElementById(hash.substr(1));
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getFAQPage());
    }, []);

    useEffect(() => {
        const hash = history.location.hash;
        // Check if there is a hash and if an element with that id exists
        const el = hash && document.getElementById(hash.substr(1));
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }, [history.location.hash]);

    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? (
                <div className="emptystate" />
            ) : (
                <div>
                    <PageBanner
                        bannerImage={APIManager.getImageBaseURL(PAGE.banner_image)}
                        bannerTitle={PAGE.banner_title}
                    />
                    <section className="page">
                        <div className="container mb-5">
                            <div className="row d-flex justify-content-lg-center">
                                <div className="col-12 col-lg-7 text-align-lg-center">
                                    <h2 className="mb-4">{PAGE.page_title}</h2>
                                    <span className="page-text">{PAGE.page_text && htmr(PAGE.page_text)}</span>
                                    {currentUser.id !== null &&
                                    currentUser.id !== undefined &&
                                    currentUser.id !== '' &&
                                    currentUser.role !== undefined &&
                                    currentUser.role.name !== undefined ? (
                                        <Link
                                            to={{
                                                pathname:
                                                    currentUser.role.name === 'Gardener'
                                                        ? '/account/drops-request'
                                                        : '/account/drops-pending',
                                                state: {},
                                            }}
                                            className="mt-4 d-block"
                                        >
                                            {I18n.t('WELCOME_TEXT') + ' ' + I18n.t('YOUR_ACCOUNT')}
                                        </Link>
                                    ) : (
                                        <Link to={'/sign-up'} className="mt-4 d-block">
                                            {I18n.t('SIGN_UP')}
                                        </Link>
                                    )}

                                    <div className="download-app">
                                        <a href="https://apps.apple.com/us/app/mulch2garden/id1613558562">
                                            <img
                                                src={
                                                    'https://mulchcms.tecdam.io/assets/1578a251-3b2a-4e14-8169-84edcbd380a3.png?width=220&height=68'
                                                }
                                            ></img>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.tecdam.mulch2garden&hl=es&gl=US">
                                            <img
                                                src={
                                                    'https://mulchcms.tecdam.io/assets/9d9d6d44-ad7c-4aed-97bd-6094e5c555d3?width=220&height=68'
                                                }
                                            ></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <VideoContainer videoURL={PAGE.video_url} col={'10'} />
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-4 mt-5 text-align-lg-center">
                                    <h3>{PAGE.faq_title}</h3>
                                </div>
                            </div>
                            {PAGE.faq_items &&
                                PAGE.faq_items.length > 0 &&
                                PAGE.faq_items.map((item: any) => {
                                    return <FaqQuestion question={item.question} answer={item.answer} id={item.id} />;
                                })}
                            <div className="row d-flex justify-content-center">
                                <div className="col-12 col-lg-9 mt-5">
                                    <h3 className="mb-4">{PAGE.faq_footer_title}</h3>
                                    <span className="page-text">
                                        {PAGE.faq_footer_text && htmr(PAGE.faq_footer_text)}
                                    </span>
                                    <div className="mt-4 faq-footer-btn">
                                        <Button title={I18n.t('SIGN_UP')} navTo={'/sign-up'} alt fullWidth />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </Wrapper>
    );
};

export default FAQPage;
