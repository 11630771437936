import { Redirect, Route, RouteProps } from "react-router-dom";

export type ProtectedRouteProps = {
    isAuthenticated: boolean | null;
    authenticationPath: string;
} & RouteProps;

const PrivateRoute = ({ isAuthenticated, authenticationPath, ...routeProps }: ProtectedRouteProps) => {
    if (isAuthenticated === null) return <></>
    if (isAuthenticated) {
        return <Route {...routeProps} />;
    } else {
        return <Redirect to={{ pathname: authenticationPath }} />;
    }
};

export default PrivateRoute;