

import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from "../../../helpers/i18n";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
    bannerImage: string,
    bannerTitle?: string
    bannerSubtitle?: string
}

const PageBanner : FC <Props> = (props) => {
    return (
        <section className="page-banner" 
            style={{backgroundImage: `url(${props.bannerImage})`}}
        >
            <div className="container h-100">
                <div className={"d-flex flex-column h-100 justify-content-center align-items-center"}>
                    {props.bannerTitle &&
                        <h1>
                            {props.bannerTitle}
                        </h1>
                    }
                    {props.bannerSubtitle &&
                        <h1>
                            {props.bannerSubtitle}
                        </h1>
                    }
                </div>
            </div>
        </section>
    );
}

export default PageBanner;