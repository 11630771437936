import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import { Link, useHistory, useParams } from 'react-router-dom';
import { forgot_password, getForgotPasswordPage } from '../../redux/pages';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import Button from '../../components/elements/button/button';
import InputField from '../../components/elements/inputfield/inputfield';
import useDebounce from '../../hooks/use-debounce';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {
}

const NewPassword : FC <Props> = (props) => {
    const { token } = useParams<{token: string}>();
    const [loader, setLoader] = useState(false);
    const [loaderForm, setLoaderForm] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(forgot_password);
    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorText, setNewPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getForgotPasswordPage());
    }, []);

    const debouncedAccountTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedAccountTerm]);

    const debouncedNewPasswordTerm = useDebounce(newPassword, 400);
    useEffect(() => {
        if (newPassword !== '') {
            let valField = Validators.validateCreateNewPassword(newPassword);
            setNewPasswordError(valField[0]);
            setNewPasswordErrorText(valField[1]);
        }
    }, [debouncedNewPasswordTerm]);

    const debouncedConfirmPasswordTerm = useDebounce(confirmPassword, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            let valField = Validators.validateConfirmNewPassword(newPassword, confirmPassword);
            setConfirmPasswordError(valField[0]);
            setConfirmPasswordErrorText(valField[1]);
        }
    }, [debouncedConfirmPasswordTerm]);

    const handleSubmit = (e:any) => {
        e.preventDefault();
        const valEmail = Validators.validateString(email);
        const valPassword = Validators.validateCreateNewPassword(newPassword);
        const valConfirmPassword = Validators.validateConfirmNewPassword(newPassword, confirmPassword);

        if (!valEmail[0]) {
            setEmailError(valEmail[0]);
            setEmailErrorText(valEmail[1]);
        } if (!valPassword[0]) {
            setNewPasswordError(valPassword[0]);
            setNewPasswordErrorText(valPassword[1]);
        } if (!valConfirmPassword[0]) {
            setNewPasswordError(valConfirmPassword[0]);
            setNewPasswordErrorText(valConfirmPassword[1]);
        } else {
            return;
        }

        sendForm();
    }

    const sendForm = () => {
        setLoaderForm(true);
        setErrorUpdateForm(false);
        APIManager.createNewPassword(token, email, newPassword)
            .then(res => {
                if (res.status === 200) {
                    history.push("/login");
                } else {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                }
            })
            .catch(err => {
                setLoaderForm(false);
                setErrorUpdateForm(true);
            })
    }

    const checkIfFormIsAllowed = () => {
        if(email !== '' && !emailError &&
            newPassword !== '' && !newPasswordError &&
            confirmPassword !== '' && !confirmPasswordError){
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    }

    useEffect(() => {
        checkIfFormIsAllowed()
    }, [email, emailError, newPassword, newPasswordError, confirmPassword, confirmPasswordError])

    return (
        <Wrapper seo_title={I18n.t('FORM_NEW_PASSWORD_FORGOT')} seo_description={PAGE.seo_description}>
            <section className="login"
                style={{backgroundImage: `url(${APIManager.getImageBaseURL(PAGE.page_background_image)})`}}
            >
                {loader ? <div className="emptystate"/> : <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="content">
                                    <div className="d-flex flex-column align-items-center mb-4">
                                        <h1 className="h2">
                                            {I18n.t('FORM_NEW_PASSWORD_FORGOT')}
                                        </h1>
                                        <span className="line -small"/>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <InputField
                                            value={email}
                                            onChange={(val) => setEmail(val.target.value)}
                                            label={I18n.t('FORM_EMAIL')}
                                            email
                                            error={emailError}
                                            errorText={emailErrorText} />
                                        <InputField 
                                            value={newPassword} 
                                            label={I18n.t('FORM_NEW_PASSWORD_FORGOT')}
                                            secure
                                            onChange={(val) => setNewPassword(val.target.value)}
                                            error={newPasswordError}
                                            errorText={newPasswordErrorText} />
                                        <InputField 
                                            value={confirmPassword} 
                                            label={I18n.t('FORM_CONFIRM_PASSWORD')}
                                            secure
                                            onChange={(val) => setConfirmPassword(val.target.value)}
                                            error={confirmPasswordError}
                                            errorText={confirmPasswordErrorText} />
                                        <Button
                                            title={I18n.t('SUBMIT')}
                                            fullWidth
                                            loader={loaderForm}
                                            disabled={disableForm} />
                                    </form>
                                    <Link
                                        to={'/sign-up'}
                                        className="sign-up-link">
                                            {I18n.t('SIGN_UP_FROM_LOGIN')}
                                    </Link>
                                    {errorUpdateForm && <div className="error-send-form">{I18n.t('FORM_SEND_ERROR')}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
        </Wrapper>
    );
}

export default NewPassword;