import React, { createRef, FC, LegacyRef, useEffect, useState } from 'react';
import API from '../../../../../../helpers/api';
import I18n from '../../../../../../helpers/i18n';
import Helpers from '../../../../../../helpers/functions';
import useDebounce from '../../../../../../hooks/use-debounce';
import InputField from '../../../../../../components/elements/inputfield/inputfield';
import Select from '../../../../../../components/elements/select/select';
import { statesUSA } from '../../../../../../helpers/statesUSA';
import { dropTypes } from '../../../../../../helpers/dropTypes';
import { dropSizes } from '../../../../../../helpers/dropSizes';
import Button from '../../../../../../components/elements/button/button';
import Geocode from 'react-geocode';
import { Wrapper } from '@googlemaps/react-wrapper';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { drops } from '../../../../../../redux/drops/index';
import { useHistory, useParams } from 'react-router-dom';

const apiKey = 'AIzaSyAF3aG9i45KWujY6CSwSiY0Sj3Ex-b60Ts';
Geocode.setApiKey(apiKey);
const APIManager = API.instance;
const Validators = Helpers.instance;

const containerStyle = {
    width: '100%',
    height: '200px',
};

interface QuizParams {
    id: string;
}

interface Props {}

const UpdateDrop: FC<Props> = (props) => {
    const { id } = useParams<QuizParams>();
    const history = useHistory();
    const currentDrops = useSelector(drops);

    useEffect(() => {
        const drop = currentDrops.drops.find((d) => d.id === parseInt(id));
        if (drop === undefined) {
            history.push('/account/drops-request');
        } else {
            setDropId(drop.id);
            setAddress(drop.address);
            setDescription(drop.description);
            setCity(drop.city);
            setZipcode(drop.zipcode);
            setState(drop.state);
            setLat(drop.latitude);
            setLng(drop.longitude);
            setDropType(drop.drop_type.type);
            setDropSize(drop.drop_size.size);
        }
    }, []);

    const [map, setMap] = useState(createRef<LegacyRef<GoogleMap>>());
    const [loaderForm, setLoaderForm] = useState(false);
    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);
    const [errorLocation, setErrorLocation] = useState(false);

    const [dropId, setDropId] = useState(0);

    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorText, setDescriptionErrorText] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState(false);
    const [zipcodeErrorText, setZipcodeErrorText] = useState('');

    const [state, setState] = useState('');
    const [stateError, setStateError] = useState(false);
    const [stateErrorText, setStateErrorText] = useState('');

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [dropType, setDropType] = useState('');
    const [dropSize, setDropSize] = useState('');

    const debouncedDescriptionTerm = useDebounce(description, 400);
    useEffect(() => {
        if (address !== '') {
            let valField = Validators.validateString(description);
            setDescriptionError(valField[0]);
            setDescriptionErrorText(valField[1]);
        }
    }, [debouncedDescriptionTerm]);

    const debouncedAddressTerm = useDebounce(address, 400);
    useEffect(() => {
        if (address !== '') {
            let valField = Validators.validateString(address);
            setAddressError(valField[0]);
            setAddressErrorText(valField[1]);
        }
    }, [debouncedAddressTerm]);

    const debouncedCityTerm = useDebounce(city, 400);
    useEffect(() => {
        if (city !== '') {
            let valField = Validators.validateString(city);
            setCityError(valField[0]);
            setCityErrorText(valField[1]);
        }
    }, [debouncedCityTerm]);

    const debouncedZipcodeTerm = useDebounce(zipcode, 400);
    useEffect(() => {
        if (zipcode !== '') {
            let valField = Validators.validateZipCode(zipcode);
            setZipcodeError(valField[0]);
            setZipcodeErrorText(valField[1]);
        }
    }, [debouncedZipcodeTerm]);

    const debouncedStateTerm = useDebounce(state, 400);
    useEffect(() => {
        if (state !== '') {
            let valField = Validators.validateString(state);
            setStateError(valField[0]);
            setStateErrorText(valField[1]);
        }
    }, [debouncedStateTerm]);

    const checkIfRegistrationIsAllowed = () => {
        if (
            address !== '' &&
            !addressError &&
            city !== '' &&
            !cityError &&
            zipcode !== '' &&
            !zipcodeError &&
            state !== '' &&
            !stateError &&
            description !== '' &&
            !descriptionError
        ) {
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    };

    const debouncedAddressLocation = useDebounce(address, 1000);
    const debouncedZipCodeLocation = useDebounce(zipcode, 1000);
    const debouncedCityLocation = useDebounce(city, 1000);
    const debouncedStateLocation = useDebounce(state, 1000);

    useEffect(() => {
        if (address === '' || zipcode === '' || city === '' || state === '') {
            return;
        }
        getCoordinates();
    }, [debouncedAddressLocation, debouncedZipCodeLocation, debouncedCityLocation, debouncedStateLocation]);

    useEffect(() => {
        checkIfRegistrationIsAllowed();
    }, [address, addressError, city, cityError, zipcode, zipcodeError, description, descriptionError]);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (disableForm) return;

        setLoaderForm(true);
        setErrorUpdateForm(false);

        const type = dropTypes.indexOf(dropType.charAt(0).toUpperCase() + dropType.slice(1)) + 1;
        const size = dropSizes.indexOf(dropSize.charAt(0).toUpperCase() + dropSize.slice(1)) + 1;

        APIManager.updateDrop(dropId, description, address, zipcode, city, state, type, size, lat, lng)
            .then((res) => {
                setLoaderForm(false);
                if (res.status === 200) {
                    history.push('/account/drops-request');
                } else {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                }
            })
            .catch((err) => {
                setLoaderForm(false);
                setErrorUpdateForm(true);
            });
    };

    const getCoordinates = () => {
        setErrorLocation(false);
        Geocode.fromAddress(`${address} ${zipcode} ${city} ${state}`).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setLat(lat);
                setLng(lng);
            },
            (error) => {
                setErrorLocation(true);
                console.error(error);
            }
        );
    };

    return (
        <form onSubmit={onSubmit}>
            <InputField
                value={address}
                label={I18n.t('FORM_ADDRESS')}
                onChange={(val) => setAddress(val.target.value)}
                error={addressError}
                errorText={addressErrorText}
            />
            <div className="row">
                <div className="col-12 col-sm-6">
                    <InputField
                        value={zipcode}
                        label={I18n.t('FORM_ZIPCODE')}
                        onChange={(val) => setZipcode(val.target.value)}
                        error={zipcodeError}
                        errorText={zipcodeErrorText}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <InputField
                        value={city}
                        label={I18n.t('FORM_CITY')}
                        onChange={(val) => setCity(val.target.value)}
                        error={cityError}
                        errorText={cityErrorText}
                    />
                </div>
            </div>
            <Select
                value={state}
                array={statesUSA}
                onChange={(val: any) => setState(val.target.value)}
                label={I18n.t('FORM_STATE')}
                error={stateError}
                errorText={stateErrorText}
            />
            {errorLocation && <span className="instructions-drop">{I18n.t('DROP_ERROR_GET_LOCATION')}</span>}
            <Wrapper apiKey={apiKey} libraries={['places']}>
                <GoogleMap
                    ref={map.current}
                    mapContainerStyle={containerStyle}
                    center={{ lat: lat, lng: lng }}
                    zoom={16}
                    options={{
                        fullscreenControl: false,
                        streetViewControl: false,
                    }}
                >
                    <Marker
                        position={{
                            lat: lat,
                            lng: lng,
                        }}
                    />
                </GoogleMap>
            </Wrapper>
            <div className="row row-type">
                <div className="col-12 col-sm-6">
                    <Select
                        value={dropType}
                        array={dropTypes}
                        onChange={(val: any) => setDropType(val.target.value)}
                        label={I18n.t('FORM_TYPE')}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Select
                        value={dropSize}
                        array={dropSizes}
                        onChange={(val: any) => setDropSize(val.target.value)}
                        label={I18n.t('FORM_SIZE')}
                    />
                </div>
            </div>
            <InputField
                value={description}
                label={I18n.t('DROP_DESCRIPTION')}
                onChange={(val) => setDescription(val.target.value)}
                error={descriptionError}
                errorText={descriptionErrorText}
                textarea
            />
            <span className="instructions-drop">{I18n.t('DROP_INSTRUCTIONS')}</span>
            <Button title={I18n.t('DROP_UPDATE_BY_GARDENER')} fullWidth loader={loaderForm} disabled={disableForm} />
            {errorUpdateForm && <span className="instructions-drop error-update">{I18n.t('FORM_SEND_ERROR')}</span>}
        </form>
    );
};

export default UpdateDrop;
