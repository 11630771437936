import React, { FC, useEffect, useState } from 'react';
import Button from '../../../../components/elements/button/button';
import I18n from "../../../../helpers/i18n";
import { Link } from "react-router-dom";
interface Props {
    role: string,
    doLogout: () => void
}

const MenuAccount: FC<Props> = (props) => { 
    return (
        <nav className='menu-account'>
            <ul className="menu-list">
                {
                    props.role === 'Gardener' &&
                    <li>
                        <Link to={'/account/create'}>
                            {I18n.t('DROP_CREATE')}
                        </Link>
                    </li>
                }
                {
                    props.role === 'Gardener' &&
                    <li>
                        <Link to={'/account/drops-request'}>
                            {I18n.t('MENU_DROPS_REQUEST')}
                        </Link>
                    </li>
                }
                {
                    props.role === 'Gardener' &&
                    <li>
                        <Link to={'/account/drops-received'}>
                            {I18n.t('MENU_DROPS_RECEIVED')}
                        </Link>
                    </li>
                }
                {
                    props.role === 'Arborist' &&
                    <li>
                        <Link to={'/account/drops-pending'}>
                            {I18n.t('MENU_DROPS_PENDING')}
                        </Link>
                    </li>
                }
                {
                    props.role === 'Arborist' &&
                    <li>
                        <Link to={'/account/drops-completed'}>
                            {I18n.t('MENU_DROPS_COMPLETED')}
                        </Link>
                    </li>
                }
                {
                    props.role === 'Arborist' &&
                    <li>
                        <Link to={'/account/requests-pending'}>
                            {I18n.t('MENU_SERVICE_REQUESTS')}
                        </Link>
                    </li>
                }
                <li>
                    <Link to={'/account/details'}>
                        {I18n.t('MENU_ACCOUNT_DETAILS')}
                    </Link>
                </li>
                <li>
                    <span onClick={props.doLogout}>
                        {I18n.t('MENU_LOGOUT')}
                    </span>
                </li>
            </ul>
        </nav>
    );
}

export default MenuAccount;