import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../../../../../helpers/api';
import I18n from '../../../../../../helpers/i18n';
import Helpers from '../../../../../../helpers/functions';
import useDebounce from '../../../../../../hooks/use-debounce';
import InputField from '../../../../../../components/elements/inputfield/inputfield';
import Select from '../../../../../../components/elements/select/select';
import { statesUSA } from '../../../../../../helpers/statesUSA';
import { dropTypes } from '../../../../../../helpers/dropTypes';
import { dropSizes } from '../../../../../../helpers/dropSizes';
import Button from '../../../../../../components/elements/button/button';
import Geocode from 'react-geocode';
import { user } from '../../../../../../redux/auth';
import MapDrop from '../../../mapDrop/mapDrop';
import { useHistory } from 'react-router-dom';

const apiKey = 'AIzaSyAF3aG9i45KWujY6CSwSiY0Sj3Ex-b60Ts';
Geocode.setApiKey(apiKey);
const APIManager = API.instance;
const Validators = Helpers.instance;

const portland = {
    lat: 45.5428688,
    lng: -122.7944847,
};

interface Props {}

const NewDrop: FC<Props> = (props) => {
    const history = useHistory();
    const currentUser = useSelector(user);
    const [loaderForm, setLoaderForm] = useState(false);
    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);
    const [errorLocation, setErrorLocation] = useState(false);

    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorText, setDescriptionErrorText] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState(false);
    const [zipcodeErrorText, setZipcodeErrorText] = useState('');

    const [state, setState] = useState('');
    const [stateError, setStateError] = useState(false);
    const [stateErrorText, setStateErrorText] = useState('');

    const [lat, setLat] = useState(portland.lat);
    const [lng, setLng] = useState(portland.lng);

    const [dropType, setDropType] = useState(dropTypes[0]);
    const [dropSize, setDropSize] = useState(dropSizes[1]);
    const [dropCost, setDropCost] = useState('$19.95 - Normal priority');
    const [dropCosts, setDropCosts] = useState(['$19.95 - Normal priority', '$49 - High priority']);

    useEffect(() => {
        if (dropSize === 'Small load < Up to 10 yards+') {
            setDropCosts(['$49 - High priority']);
        } else {
            setDropCosts(['$19.95 - Normal priority', '$49 - High priority']);
        }
    }, [dropSize]);

    useEffect(() => {
        currentUser.address !== '' && setAddress(currentUser.address);
        currentUser.city !== '' && setCity(currentUser.city);
        currentUser.zipcode !== '' && setZipcode(currentUser.zipcode);
        currentUser.state !== '' && setState(currentUser.state);
    }, [currentUser]);

    const debouncedDescriptionTerm = useDebounce(description, 400);
    useEffect(() => {
        if (address !== '') {
            let valField = Validators.validateString(description);
            setDescriptionError(valField[0]);
            setDescriptionErrorText(valField[1]);
        }
    }, [debouncedDescriptionTerm]);

    const debouncedAddressTerm = useDebounce(address, 400);
    useEffect(() => {
        if (address !== '') {
            let valField = Validators.validateString(address);
            setAddressError(valField[0]);
            setAddressErrorText(valField[1]);
        }
    }, [debouncedAddressTerm]);

    const debouncedCityTerm = useDebounce(city, 400);
    useEffect(() => {
        if (city !== '') {
            let valField = Validators.validateString(city);
            setCityError(valField[0]);
            setCityErrorText(valField[1]);
        }
    }, [debouncedCityTerm]);

    const debouncedZipcodeTerm = useDebounce(zipcode, 400);
    useEffect(() => {
        if (zipcode !== '') {
            let valField = Validators.validateZipCode(zipcode);
            setZipcodeError(valField[0]);
            setZipcodeErrorText(valField[1]);
        }
    }, [debouncedZipcodeTerm]);

    const debouncedStateTerm = useDebounce(state, 400);
    useEffect(() => {
        if (state !== '') {
            let valField = Validators.validateString(state);
            setStateError(valField[0]);
            setStateErrorText(valField[1]);
        }
    }, [debouncedStateTerm]);

    const checkIfRegistrationIsAllowed = () => {
        if (
            address !== '' &&
            !addressError &&
            city !== '' &&
            !cityError &&
            zipcode !== '' &&
            !zipcodeError &&
            state !== '' &&
            !stateError &&
            description !== '' &&
            !descriptionError
        ) {
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    };

    const debouncedAddressLocation = useDebounce(address, 1000);
    const debouncedZipCodeLocation = useDebounce(zipcode, 1000);
    const debouncedCityLocation = useDebounce(city, 1000);
    const debouncedStateLocation = useDebounce(state, 1000);

    useEffect(() => {
        if (address === '' || zipcode === '' || city === '' || state === '') {
            return;
        }
        getCoordinates();
    }, [debouncedAddressLocation, debouncedZipCodeLocation, debouncedCityLocation, debouncedStateLocation]);

    useEffect(() => {
        checkIfRegistrationIsAllowed();
    }, [address, addressError, city, cityError, zipcode, zipcodeError, description, descriptionError]);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (disableForm) return;

        setLoaderForm(true);
        setErrorUpdateForm(false);

        const type = dropTypes.indexOf(dropType) + 1;
        const size = dropSizes.indexOf(dropSize) + 1;

        APIManager.createDrop(
            description,
            address,
            zipcode,
            city,
            state,
            type,
            size,
            lat,
            lng,
            image !== undefined && image !== null ? image : undefined
        )
            .then((res) => {
                setLoaderForm(false);
                if (res.status === 200) {
                    // if (dropCost === '$49 - High priority') {
                    //     checkout(res.data.data.id, 49);
                    // } else {
                    //     checkout(res.data.data.id, 19.95);
                    // }
                    history.push('/account/drops-request');
                } else {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                }
            })
            .catch((err) => {
                setLoaderForm(false);
                setErrorUpdateForm(true);
            });
    };

    const getCoordinates = () => {
        setErrorLocation(false);
        Geocode.fromAddress(`${address} ${zipcode} ${city} ${state}`).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setLat(lat);
                setLng(lng);
            },
            (error) => {
                setErrorLocation(true);
                console.error(error);
            }
        );
    };

    const checkout = (id: number, cost: number) => {
        APIManager.checkout(id, cost)
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = res.data.url;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const fileInput = useRef(null);

    const handleFile = (file: any) => {
        console.log(file);
        setImage(file);
        setPreviewUrl(URL.createObjectURL(file));
    };
    const handleOndragOver = (event: any) => {
        event.preventDefault();
    };

    const handleOndrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        let imageFile = event.dataTransfer.files[0];
        handleFile(imageFile);
    };
    return (
        <form onSubmit={onSubmit}>
            <InputField
                value={address}
                label={I18n.t('FORM_ADDRESS')}
                onChange={(val) => setAddress(val.target.value)}
                error={addressError}
                errorText={addressErrorText}
            />
            <div className="row">
                <div className="col-12 col-sm-6">
                    <InputField
                        value={zipcode}
                        label={I18n.t('FORM_ZIPCODE')}
                        onChange={(val) => setZipcode(val.target.value)}
                        error={zipcodeError}
                        errorText={zipcodeErrorText}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <InputField
                        value={city}
                        label={I18n.t('FORM_CITY')}
                        onChange={(val) => setCity(val.target.value)}
                        error={cityError}
                        errorText={cityErrorText}
                    />
                </div>
            </div>
            <Select
                value={state}
                array={statesUSA}
                onChange={(val: any) => setState(val.target.value)}
                label={I18n.t('FORM_STATE')}
                error={stateError}
                errorText={stateErrorText}
            />
            {errorLocation && <span className="instructions-drop">{I18n.t('DROP_ERROR_GET_LOCATION')}</span>}
            <MapDrop lat={lat} lng={lng} />
            <div className="row row-type">
                <div className="col-12 col-sm-6">
                    <Select
                        value={dropType}
                        array={dropTypes}
                        onChange={(val: any) => setDropType(val.target.value)}
                        label={I18n.t('FORM_TYPE')}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Select
                        value={dropSize}
                        array={dropSizes}
                        onChange={(val: any) => setDropSize(val.target.value)}
                        label={I18n.t('FORM_SIZE')}
                    />
                </div>
            </div>
            <InputField
                value={description}
                label={I18n.t('DROP_DESCRIPTION')}
                onChange={(val) => setDescription(val.target.value)}
                error={descriptionError}
                errorText={descriptionErrorText}
                textarea
            />
            <span className="instructions-drop">{I18n.t('DROP_INSTRUCTIONS')}</span>
            <Select
                value={dropCost}
                array={dropCosts}
                onChange={(val: any) => {
                    setDropCost(val.target.value);
                    if (val.target.value === '$19.95 - Normal priority') {
                        setDropSize('Normal load < Up to 15 yards+');
                    }
                }}
                label={I18n.t('DROP_COST')}
            />
            {dropCost === '$19.95 - Normal priority' && (
                <span className="instructions-drop">{I18n.t('DROP_INSTRUCTIONS_FREE')}</span>
            )}
            <div id="form-file-upload">
                <label id="label-file-upload" htmlFor="input-file-upload">
                    <div onDragOver={handleOndragOver} onDrop={handleOndrop}>
                        <p>{I18n.t('UPLOAD_DRAG_DROP')}</p>
                        <p className="upload-button">{I18n.t('UPLOAD_CLICK')}</p>
                        <input
                            ref={fileInput}
                            hidden
                            type="file"
                            id="input-file-upload"
                            multiple={false}
                            accept="image/png, image/jpeg"
                            onChange={(e: any) => handleFile(e.target?.files[0])}
                        />
                    </div>
                </label>
            </div>
            {previewUrl && (
                <div className="uploadImage">
                    <img className="imageUpload" src={previewUrl} alt="image" />
                </div>
            )}
            <Button title={I18n.t('DROP_CREATE')} fullWidth loader={loaderForm} disabled={disableForm} />
            {errorUpdateForm && <span className="instructions-drop error-update">{I18n.t('FORM_SEND_ERROR')}</span>}
        </form>
    );
};

export default NewDrop;
