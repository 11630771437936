import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import VideoContainer from '../../components/blocks/video-container/video-container';
import { Link } from 'react-router-dom';
import { getAboutArboristPage, about_arborist } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
const APIManager = API.instance;
interface Props {
}

const AboutArboristPage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(about_arborist);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getAboutArboristPage());
    }, []);
   
    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? <div className="emptystate"/> : <div>
                <section className="page">
                    <div className="container mb-5">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-7 text-align-center">
                                <h2 className="mb-4">
                                    {PAGE.page_title}
                                </h2>
                                <span className="page-text">
                                    {PAGE.page_text && htmr(PAGE.page_text)} 
                                </span>
                                <div className="download-app">
                                    <a href='https://apps.apple.com/us/app/mulch2garden/id1613558562'>
                                        <img src={'https://mulchcms.tecdam.io/assets/1578a251-3b2a-4e14-8169-84edcbd380a3.png?width=220&height=68'}></img>
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=com.tecdam.mulch2garden&hl=es&gl=US'>
                                        <img src={'https://mulchcms.tecdam.io/assets/9d9d6d44-ad7c-4aed-97bd-6094e5c555d3?width=220&height=68'}></img>
                                    </a>
                                </div>
                                <span>
                                    {'The Arborist delivery process is simple (quick steps) and the service is available both on the website or via the app store.'}
                                </span>
                            </div>
                        </div>
                    </div>
                    {PAGE.video_url &&
                        <VideoContainer
                            videoURL={PAGE.video_url}
                            col={"10"}
                        />
                    }
                    <div className="container mt-5 mb-5">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-lg-9">
                                <span className="page-text">
                                    {PAGE.delivery_process_explained && htmr(PAGE.delivery_process_explained)} 
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>}
        </Wrapper>
    );
}

export default AboutArboristPage;