import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import I18n from '../../../../helpers/i18n';
import { interfaceDrop } from '../../../../models/drop';
import { user } from '../../../../redux/auth';
import { drops } from '../../../../redux/drops';
import DropCardGardener from '../dropCard/dropCardGardener';
import sort_by from '../../../../images/sort_by.svg';
import { useHistory } from 'react-router-dom';
import API from '../../../../helpers/api';

const APIManager = API.instance;
interface Props {}

const DropsReceived: FC<Props> = (props) => {
    const history = useHistory();
    const currentDrops = useSelector(drops);
    const currentUser = useSelector(user);
    const [sort, setSort] = useState(true);

    const conditionReceived = (drop: interfaceDrop) => {
        if (drop === undefined) {
            return false;
        }
        return currentUser.id === drop.user_created.id && drop.drop_status.id === 3;
    };

    const payDrop = (drop: any) => {
        APIManager.checkout(drop.id, drop.priority.id === 1 ? 19.95 : 49)
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = res.data.url;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <section className="drops-received">
            <div className="d-flex justify-content-between">
                <h3>{I18n.t('MENU_DROPS_RECEIVED')}</h3>
                <img src={sort_by} alt="sort drops" onClick={() => setSort(!sort)} className="img-sort" />
            </div>
            {currentDrops.drops.filter((drop: interfaceDrop) => conditionReceived(drop)).length === 0 && (
                <p>{I18n.t('DROP_ZERO')}</p>
            )}
            {[...currentDrops.drops]
                .sort((a, b) => (a.id > b.id ? (sort ? -1 : 1) : b.id > a.id ? (sort ? 1 : -1) : 0))
                .map((drop: interfaceDrop) => {
                    if (conditionReceived(drop)) {
                        return (
                            <DropCardGardener
                                drop={drop}
                                key={drop.id}
                                onRequestService={() => history.push(`/account/request-service/${drop.id}`)}
                                completePayment={payDrop}
                            />
                        );
                    }
                })}
        </section>
    );
};

export default DropsReceived;
