export default class Cookie { 
    static instance: Cookie = new Cookie();

    setCookie = (name: string, value: any, days: number) => {
        let expires = ""; 
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // 30 days
        expires = "expires=" + date.toUTCString();
        document.cookie = `${name}=${value}; ${expires}; SameSite=Strict`;
    }

    getCookie = (name: string) => {
        let nameEQ = `${name}=`;
        let ca = document.cookie.split(";"); 
        for (var i = 0; i < ca.length; i++) { 
            let c = ca[i];
            while (c.charAt(0) === " ") c = c.substring(1, c.length); 
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    deleteCookie = (name: string) => {
        document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    }
}