import React, { FC } from 'react';
import { useHistory } from "react-router-dom";
interface Props {
    title: string,
    onClick?: any,
    navTo?: string,
    loader?: boolean,
    disabled?: boolean,
    //styles
    style?: string,
    fullWidth?: boolean,
    small?: boolean,
    clear?: boolean,
    alt?: boolean
}
const Button : FC <Props> = (props) => {
    const history = useHistory();

    const onPress = (e: any) => {
        if(props.onClick){
            e.preventDefault();
            props.onClick();
        }
        if(props.navTo){
            e.preventDefault();
            history.push(props.navTo);
        }
    }

    const getStyle = () => {
        let style = "btn-filled";

        if(props.clear){
            style = "btn-option";
        }
        
        if(props.style){
            style = props.style;
        } else {
            if(props.fullWidth){
                style = style + " -fullwidth";
            }

            if(props.small){
                style = style + " -small";
            }

            if(props.alt){
                style = style + " -alt";
            }
            
            if(props.disabled){
                style = style + " -disabled";
            }
        }

        return style;
    }

    return (
        <div>
            {props.loader ?
                <div className="btn-filled -disabled">
                    <div className="btn-loader d-flex justify-content-center">
                        <div className="loader"/>
                    </div>
                </div>
            :
                <button
                    className={getStyle()}
                    onClick={onPress}
                >
                    <span className="paragraph-regular btn-text">
                        {props.title}
                    </span>
                </button>
            }
        </div>
    );
}
export default Button;