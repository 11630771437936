import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import I18n from "../../helpers/i18n";
import { Link, useHistory } from 'react-router-dom';
import { forgot_password, getForgotPasswordPage } from '../../redux/pages';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import Button from '../../components/elements/button/button';
import InputField from '../../components/elements/inputfield/inputfield';
import useDebounce from '../../hooks/use-debounce';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {
}

const ForgotPassword : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const [loaderForm, setLoaderForm] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(forgot_password);
    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);
    const [completedForm, setCompletedForm] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getForgotPasswordPage());
    }, []);

    const debouncedAccountTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedAccountTerm]);

    const handleSubmit = (e:any) => {
        e.preventDefault();
        let valEmail = Validators.validateString(email);

        if (!valEmail[0]) {
            setEmailError(valEmail[0]);
            setEmailErrorText(valEmail[1]);
        } else {
            return;
        }

        sendForm();
    }

    const sendForm = () => {
        setLoaderForm(true);
        setErrorUpdateForm(false);
        APIManager.forgotPassword(email)
            .then(res => {
                if (res.status === 200) {
                    setLoaderForm(false);
                    setCompletedForm(true);
                } else {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                }
            })
            .catch(err => {
                setLoaderForm(false);
                setErrorUpdateForm(true);
            })
    }

    const checkIfFormIsAllowed = () => {
        if(email !== '' && !emailError){
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    }

    useEffect(() => {
        checkIfFormIsAllowed()
    }, [email, emailError])

    return (
        <Wrapper seo_title={PAGE.page_title} seo_description={PAGE.seo_description}>
            <section className="login"
                style={{backgroundImage: `url(${APIManager.getImageBaseURL(PAGE.page_background_image)})`}}
            >
                {loader ? <div className="emptystate"/> : <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="content">
                                    <div className="d-flex flex-column align-items-center mb-4">
                                        <h1 className="h2">
                                            {PAGE.page_title}
                                        </h1>
                                        <span className="line -small"/>
                                        <p>{I18n.t('TEXT_FORGOT_PASSWORD')}</p>
                                    </div>
                                    {
                                        completedForm ?
                                        <p>{I18n.t('TEXT_FORGOT_PASSWORD_SENT')}</p>
                                        :
                                        <form onSubmit={handleSubmit}>
                                            <InputField
                                                value={email}
                                                onChange={(val) => setEmail(val.target.value)}
                                                label={I18n.t('FORM_EMAIL')}
                                                email
                                                error={emailError}
                                                errorText={emailErrorText}
                                            />
                                            <Button
                                                title={I18n.t('FORM_SUBMIT')}
                                                fullWidth
                                                loader={loaderForm}
                                                disabled={disableForm} />
                                        </form>
                                    }
                                    
                                    <Link
                                        to={'/sign-up'}
                                        className="sign-up-link">
                                            {I18n.t('SIGN_UP_FROM_LOGIN')}
                                    </Link>
                                    {errorUpdateForm && <div className="error-send-form">{I18n.t('FORM_SEND_ERROR')}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
        </Wrapper>
    );
}

export default ForgotPassword;