import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import PageBanner from '../../components/blocks/page-banner/page-banner';
import { Link, useHistory } from 'react-router-dom';
import { getDonationPage, donation } from '../../redux/pages';
import API from '../../helpers/api';
import htmr from 'htmr';
import Button from '../../components/elements/button/button';
import I18n from '../../helpers/i18n';

const APIManager = API.instance;
interface Props {}

const DonationPage: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGE = useSelector(donation);

    useEffect(() => {
        if (loader && PAGE !== '') {
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getDonationPage());
    }, []);

    return (
        <Wrapper seo_title={PAGE.seo_title} seo_description={PAGE.seo_description}>
            {loader ? (
                <div className="emptystate" />
            ) : (
                <div>
                    <PageBanner
                        bannerImage={
                            PAGE.banner_image &&
                            PAGE.banner_image.id !== undefined &&
                            APIManager.getImageBaseURL(PAGE.banner_image.id)
                        }
                        bannerTitle={PAGE.banner_title}
                    />
                    <section className="page -about">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-12 col-lg-7 d-flex flex-column align-items-center">
                                    <h2>{PAGE.page_title}</h2>
                                    <span className="line" />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center mb-5 pb-lg-5">
                                <div className="col-12 col-lg-7 text-align-center">
                                    <span className="page-text">
                                        {PAGE.donation_description && htmr(PAGE.donation_description)}
                                    </span>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center mb-4">
                                <div className="mb-4 col-lg-7 d-flex flex-column align-items-center">
                                    <Button
                                        title={I18n.t('DONATE_NOW')}
                                        onClick={() => window.open(PAGE.donation_link, '_blank')}
                                        alt
                                        small
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </Wrapper>
    );
};

export default DonationPage;
